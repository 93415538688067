import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import contactImage from "../images/kontakt.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledContact = styled.section`
  padding-bottom: 100px;
 
  .brand-logo-small,
  .nav-links li {
    &.d-none {
      display: none !important;
    }
  }
  .lang-switcher {
    li {
      button {
        &.d-none {
          pointer-events: none;
        }
      }
    }
  }
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 73px 0 80px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    .d-none {
        display: none !important;
      }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 20%;
    }
    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      margin-bottom: 160px;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      &.d-none {
        display: none;
      }
    }
  }
  .content-wrapper {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    .d-none {
        display: none !important;
      }
  }
  .contact-form {
    margin: 0 auto;
    width: 100%;
    h3,
    h4 {
      font-size: 1.625rem;
      font-weight: 600;
      margin-bottom: 40px;
      width: 100%;
      text-align: center;
      line-height: 1.3em;
    }
    h4 {
      font-size: 1.5rem;
    }
    .quick-info {
      font-size: 1.125rem;
      margin-bottom: 2.5rem;
      line-height: 1.5em;
      text-align: center;
      a {
      text-align: center;
      color: #bf825a;
      font-weight: 600;
      margin: 0 auto;
      display: inline-block;
      transition: all .2s ease-out;
      &:hover {
        color #000;
      }
    }
    }
    form {
      border: 1px solid #000;
      padding: 30px;
      margin-bottom: 80px;
      font-family: 'Montserrat', sans-serif;
    }
    div {
      margin-bottom: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      label {
        font-size: 1.125rem;
        margin-bottom: 10px;
        font-weight: 500;
      }
      input,
      textarea {
        padding: 10px;
        font-size: 1.125rem;
        color: #000;
        border: 1px solid #000;
        &:focus,
        &:active {
          border: 1px solid #bf825a;
          outline: 1px solid #bf825a;
        }
      }
      textarea {
        height: 150px;
      }
    }
    .btn-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin: 0;
    }
    .btn-send {
      font-family: "Montserrat", sans-serif;
      background: transparent;
      outline: none;
      border: none;
      padding: 8px 16px;
      position: relative;
      font-size: 0.875rem;
      border: 1px solid #2e2b26;
      color: #2e2b26;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-weight: 600;
      transition: 0.25s ease-out;
      cursor: pointer;
      text-decoration: none;
      margin: 0 auto;
      &:hover {
        background: #bf825a;
        border: 1px solid #bf825a;
        color: #fff;
      }
    }
  
  }

  @media(min-width: 991px) and (min-height: 400px) and (max-height: 750px) {
    .posts-image-wrapper {
     h1 {
      margin-bottom: 40px;
     }
    }
  }

  @media (max-width: 991px) {
    .posts-image-wrapper {
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
      }
      img {
        height: 50vh;
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      height: calc(40vh - 68px);
      margin-bottom: 100px;
      img {
        height: 100%;
      }
      h1 {
        margin: auto 0 -80px 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
      }
    }
    .contact-form {
      padding-top: 40px;
      h3 {
        font-size: 1.25rem;
      }
      h4 {
        font-size: 1.125rem;
        margin-bottom: 30px;
      }
      .quick-info {
        font-size: .9375rem;
      }
      form {
        label, input {
          font-size: .9375rem;
        }
      }
    }
  }
`;

export default function Contact() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isEnglish, setEnglish] = useState("true");
  const ToggleEnglish = () => {
    setEnglish(!isEnglish);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledContact>
      <Helmet>
        <title>Aleksandra</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink
          to="/"
          className={isEnglish ? "brand-logo-small d-none" : "brand-logo-small"}
        >
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <NavLink
          to="/home-en"
          className={
            isEnglish ? "brand-logo-small" : " brand-logo-small d-none"
          }
        >
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <button
              type="button"
              className={isEnglish ? "d-none" : ""}
              onClick={ToggleEnglish}
            >
              PL
            </button>
          </li>
          <li className="lang-en">
            <button
              type="button"
              className={isEnglish ? "" : "d-none"}
              onClick={ToggleEnglish}
            >
              EN
            </button>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/">Biography</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li className={isEnglish ? "second-media" : "second-media d-none"}>
              <div
                className={isVisible ? "media-wrapper" : "media-wrapper active"}
              >
                <NavLink to="/filmy">Filmy</NavLink>
                <NavLink to="/galeria">Zdjęcia</NavLink>
              </div>
            </li>
            <li className={isEnglish ? "d-none second-media" : "second-media"}>
              <div
                className={isVisible ? "media-wrapper" : "media-wrapper active"}
              >
                <NavLink to="/movies">Movies</NavLink>
                <NavLink to="/galeria">Photos</NavLink>
              </div>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1 className={isEnglish ? "" : "d-none"}>Kontakt</h1>
        <h1 className={isEnglish ? "d-none" : ""}>Contact</h1>
        <img
          className="image-desktop"
          src={contactImage}
          alt="Aleksandra Czajor-Kowalska"
        />
      </div>
      <div className="content-wrapper">
        <div className="contact-form">
          <p className={isEnglish ? "quick-info" : "quick-info d-none"}>
            Jesteś zainteresowany współpracą z Airis String Quartet ? Skontaktuj
            się z nami za pomocą formularza kontaktowego lub bezpośrednio
            mailowo na adres{" "}
            <a rel="noreferrer" href="mailto:airisquartet@gmail.com">
              airisquartet@gmail.com
            </a>{" "}
            lub telefonicznie pod numerem{" "}
            <a rel="noreferrer" href="tel:880008035">
              880008035
            </a>
          </p>
          <p className={isEnglish ? "quick-info d-none" : "quick-info"}>
            Are you interested in co-operation with Airis String Quartet? Do not
            hesitate to contact us via contact form or directly by e-mail{" "}
            <a rel="noreferrer" href="mailto:airisquartet@gmail.com">
              airisquartet@gmail.com
            </a>{" "}
            or phone{" "}
            <a rel="noreferrer" href="tel:880008035">
              880008035
            </a>
          </p>
          <form method="post" name="contactform" action="/podziekowanie">
            <input type="hidden" name="form-name" value="contactform" />
            <div>
              <label for="name" className={isEnglish ? "" : "d-none"}>
                Imię:
              </label>
              <label for="name" className={isEnglish ? "d-none" : ""}>
                Name:
              </label>
              <input type="text" id="name" name="name" />
            </div>
            <div>
              <label for="email" className={isEnglish ? "" : "d-none"}>
                Adres e-mail:
              </label>
              <label for="email" className={isEnglish ? "d-none" : ""}>
                E-mail address:
              </label>
              <input type="text" id="email" name="email" />
            </div>
            <div>
              <label for="text" className={isEnglish ? "" : "d-none"}>
                Wiadomość:
              </label>
              <label for="text" className={isEnglish ? "d-none" : ""}>
                Message:
              </label>
              <textarea type="text" id="text" name="text" />
            </div>
            <div className="btn-wrapper">
              <input
                type="submit"
                value="Wyślij wiadomość"
                className={isEnglish ? "btn-send" : "d-none btn-send"}
              />
              <input
                type="submit"
                value="Send message"
                className={isEnglish ? "d-none btn-send" : "btn-send"}
              />
            </div>
          </form>
          {/* <h4 className={isEnglish ? "" : "d-none"}>
            Lub bezpośrednio na adres e-mail:
          </h4>
          <h4 className={isEnglish ? "d-none" : ""}>
            Contact directly by e-mail:
          </h4>
          <a rel="noreferrer" href="mailto:airisquartet@gmail.com">
            airisquartet@gmail.com
          </a> */}
        </div>
      </div>
    </StyledContact>
  );
}
