import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";

const StyledMovies = styled.section`
  margin: 160px 0 60px;
  .movies-wrapper {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    font-family: "Libre Baskerville", serif;
  }
  .single-movie {
    margin: 0 auto 120px;
    padding: 0 20px;
    p {
      font-size: 1rem;
      color: #000;
      line-height: 1.3em;
      margin: 0;
      font-weight: 500;
      em {
        font-style: italic;
      }
    }
    iframe {
      width: 760px;
      height: 425px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 991px) {
    .single-movie {
      p {
        width: 700px;
      }
      iframe {
        width: 700px;
        height: 392px;
      }
    }
  }
  @media (max-width: 767px) {
    margin: 130px 0 60px;
    .single-movie {
      p {
        width: 600px;
        font-size: 0.9375rem;
      }
      iframe {
        width: 600px;
        height: 336px;
      }
    }
  }
  @media (max-width: 650px) {
    .single-movie {
      p {
        width: 520px;
      }
      iframe {
        width: 520px;
        height: 291px;
      }
    }
  }
  @media (max-width: 575px) {
    .single-movie {
      margin: 0 auto 80px;
      p {
        width: 450px;
        font-size: 0.875rem;
      }
      iframe {
        width: 450px;
        height: 252px;
      }
    }
  }
  @media (max-width: 500px) {
    .single-movie {
      p {
        width: 400px;
      }
      iframe {
        width: 400px;
        height: 224px;
      }
    }
  }
  @media (max-width: 450px) {
    .single-movie {
      p {
        width: 350px;
      }
      iframe {
        width: 350px;
        height: 196px;
      }
    }
  }
  @media (max-width: 400px) {
    .single-movie {
      p {
        width: 330px;
      }
      iframe {
        width: 330px;
        height: 184px;
      }
    }
  }
  @media (max-width: 380px) {
    .single-movie {
      p {
        width: 310px;
      }
      iframe {
        width: 310px;
        height: 173px;
      }
    }
  }
  @media (max-width: 359px) {
    .single-movie {
      p {
        width: 290px;
      }
      iframe {
        width: 290px;
        height: 162px;
      }
    }
  }
  @media (max-width: 340px) {
    .single-movie {
      p {
        width: 270px;
      }
      iframe {
        width: 270px;
        height: 151px;
      }
    }
  }
  @media (max-width: 320px) {
    .single-movie {
      p {
        width: 250px;
      }
      iframe {
        width: 250px;
        height: 140px;
      }
    }
  }
`;

export default function MoviesEn() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledMovies>
      <Helmet>
        <title>Movies</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/filmy">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/movies">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>

      <div className="movies-wrapper">
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/3INkhvZVSQM"
            frameBorder="0"
          ></iframe>
          <p>
            Henryk Czyż -{" "}
            <em>
              {" "}
              Etiuda jazzowa na kwartet smyczkowy/ Jazz etude for string quartet
            </em>
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/FgDnl94X80I?start=11193"
            frameBorder="0"
          ></iframe>
          <p>
            Krzysztof Penderecki - <em> Quartetto per archi no 2</em>
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/YPVQQ-FhrHo"
            frameBorder="0"
          ></iframe>
          <p>
            Michał Dobrzyński - <em> InGarden Quartet</em>
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/Lz8hftkFrow"
            frameBorder="0"
          ></iframe>
          <p>
            <em> Hartmann </em> - Album trailer/ CD Accord Music Edition
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/WxkTBJJBEMU"
            frameBorder="0"
          ></iframe>
          <p>
            A. Berg - <em> Lyric suite </em> mvt 6 <em> Largo desolato </em>{" "}
            (excerpt) - Airis String Quartet/ Dorota Szczepańska soprano
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/tbTIAyseYZ8"
            frameBorder="0"
          ></iframe>
          <p>
            W. Lutosławski - <em> 5 melodii ludowych </em> (aranżacja na kwartet
            smyczkowy)/ <em> 5 folk melodies </em> (arranged for string quartet){" "}
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/Hp9zhWqFtn8"
            frameBorder="0"
          ></iframe>
          <p>
            L. van Beethoven - String Quartet f minor <em> Serioso </em> op. 95
            mvt 1
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/w0A-wivFDXU"
            frameBorder="0"
          ></iframe>
          <p>
            Antonín Dvořák - String Quartet F major <em> American </em> mvt 4
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/97m5oZLvWOg"
            frameBorder="0"
          ></iframe>
          <p>
            <em> GINCZANKA </em> - Airis String Quartet/ Nocą Umówieni
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/kEJOKGv8Ack"
            frameBorder="0"
          ></iframe>
          <p>Airis String Quartet/ Amadrums Trio/ Łukasz Pieprzyk</p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/20oVS0ovbpQ"
            frameBorder="0"
          ></iframe>
          <p>
            Airis String Quartet/ Amadrums Trio/ Łukasz Pieprzyk -{" "}
            <em> Calm </em>
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/W6lISDRcmfA"
            frameBorder="0"
          ></iframe>
          <p>
            Christian Mason - <em> Inner Landscapes </em> mvt 3 - Madoka Okada/
            Airis String Quartet
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/zxiQ2VWhn_M?start=895"
            frameBorder="0"
          ></iframe>
          <p>
            Dawid Kusz - <em> Miserere Mei Deus </em> for string quartet and
            vocal octet
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/__RVDn_KDzA"
            frameBorder="0"
          ></iframe>
          <p>
            K.A. Janczak - <em> Dans les pas de Marie Curie </em> movie
            soundtrack
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/uM0tk9Qsw54"
            frameBorder="0"
          ></iframe>
          <p>
            Video Game <em> Kholat </em> official trailer / soundtrack by
            Arkadiusz Reikowski
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://player.vimeo.com/video/223517796?"
            frameBorder="0"
          ></iframe>
          <p>
            <em> BLAME </em> - short film directed by Sally Fenaux Barleycorn
          </p>
        </div>
        <div className="single-movie">
          <iframe
            src="https://www.youtube.com/embed/_WagxWsX7tM"
            frameBorder="0"
          ></iframe>
          <p>
            Daddy's Cash Band / Airis String Quartet - <em> Good Morning</em>
          </p>
        </div>
      </div>
    </StyledMovies>
  );
}
