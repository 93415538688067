import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollToTop";
import Footer from "./components/Footer";
import Home from "./components/Home";
import HomeEn from "./components/Home-en";
import Bio from "./components/Bio";
import BioEn from "./components/Bio-en";
import SinglePost from "./components/SinglePost";
import SingleProject from "./components/SingleProject";
import Post from "./components/Post";
import PostEN from "./components/Post-en";
import Concert from "./components/Concert";
import ConcertEN from "./components/Concert-en";
import Aleksandra from "./components/Aleksandra";
import AleksandraEN from "./components/Aleksandra-en";
import Grazyna from "./components/Grazyna";
import GrazynaEN from "./components/Grazyna-en";
import Malwina from "./components/Malwina";
import MalwinaEN from "./components/Malwina-en";
import Mateusz from "./components/Mateusz";
import MateuszEN from "./components/Mateusz-en";
import Gallery from "./components/Gallery";
import Movies from "./components/Movies";
import MoviesEN from "./components/Movies-en";
import Repertoire from "./components/Repertoire";
import Discography from "./components/Discography";
import DiscographyEN from "./components/Discography-en";
import Collaboration from "./components/Collaboration";
import CollaborationEN from "./components/Collaboration-en";
import Contact from "./components/Contact";
import GlobalCSS from "./global.css";
import "./fonts.css";

function App() {
  return (
    <BrowserRouter>
      <GlobalCSS />
      <ScrollToTop />
      <Routes>
        <Route element={<Home />} path="/" exact />
        <Route element={<HomeEn />} path="/home-en" />
        <Route element={<Bio />} path="/biografia" />
        <Route element={<BioEn />} path="/biography" />
        <Route element={<SinglePost />} path="/aktualnosci/:slug" />
        <Route element={<Post />} path="/aktualnosci" />
        <Route element={<PostEN />} path="/posts" />
        <Route element={<Concert />} path="/koncerty" />
        <Route element={<ConcertEN />} path="/concerts" />
        <Route element={<Aleksandra />} path="/aleksandra" />
        <Route element={<AleksandraEN />} path="/aleksandra-en" />
        <Route element={<Grazyna />} path="/grazyna" />
        <Route element={<GrazynaEN />} path="/grazyna-en" />
        <Route element={<Malwina />} path="/malwina" />
        <Route element={<MalwinaEN />} path="/malwina-en" />
        <Route element={<Mateusz />} path="/mateusz" />
        <Route element={<MateuszEN />} path="/mateusz-en" />
        <Route element={<Gallery />} path="/galeria" />
        <Route element={<Movies />} path="/filmy" />
        <Route element={<MoviesEN />} path="/movies" />
        <Route element={<Repertoire />} path="/repertuar" />
        <Route element={<Discography />} path="/dyskografia" />
        <Route element={<DiscographyEN />} path="/discography" />
        <Route element={<Collaboration />} path="/wspolpraca" />
        <Route element={<CollaborationEN />} path="/partnership" />
        <Route element={<SingleProject />} path="/wspolpraca/:slug" />
        <Route element={<Contact />} path="/kontakt" />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
