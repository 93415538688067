import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import sanityClient from "../client.js";
import { Link, NavLink } from "react-router-dom";
import postsImage from "../images/posts-image.jpg";
import postsImageMobile from "../images/posts-image-mobile.jpg";
import arrowRight from "../images/arrow-right-small.svg";
import styled from "styled-components";
import imageUrlBuilder from "@sanity/image-url";
import homeImage from "../images/home-image.jpg";
import pageLogo from "../images/airis-logo-small.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const StyledPosts = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 73px 0 160px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.052240964745273066) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
    }
    .image-mobile {
      display: none;
    }
    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      margin-bottom: 160px;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
    }
  }
  .posts-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  figure {
    margin: 0;
  }
  .single-post {
    &:nth-child(even) {
      .inside-post {
        flex-direction: row-reverse;
      }
    }
    &:not(:last-child) {
      .inside-post {
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 32px;
          width: calc(100% - 64px);
          height: 1px;
          background: #bf825a;
          z-index: 5;
        }
      }
    }
  }
  .inside-post {
    display: flex;
    padding-bottom: 80px;
    margin-bottom: 80px;
    position: relative;
    .content-col,
    .image-col {
      padding: 0 32px;
      width: 50%;
    }
  }
  a {
    text-decoration: none;
  }
  .content-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    h3 {
      font-weight: 700;
      color: #2e2b26;
      font-size: 1.5rem;
      line-height: 1.5em;
      margin-bottom: 32px;
      transition: 0.25s ease-out;
      &:hover {
        color: #bf825a;
      }
    }
  }
  .read-more {
    margin-left: auto;
    background: transparent;
    outline: none;
    border: none;
    text-decoration: underline;
    padding: 0 36px 0 0;
    position: relative;
    font-size: 1rem;
    line-height: 1.5em;
    color: #2e2b26;
    font-weight: 400;
    &:after {
      content: "";
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background: url(${arrowRight}) no-repeat center center;
      background-size: contain;
      transition: 0.25s ease-out;
    }
    &:hover {
      &:after {
        transform: translateY(-50%) translateX(10px);
      }
    }
  }
  .short-text {
    font-size: 1rem;
    line-height: 1.5em;
    color: #2e2b26;
    margin-bottom: 20px;
    text-align: justify;
    font-weight: 400;
    + a {
      margin-left: auto;
    }
  }
  .image-col {
    span {
      overflow: hidden;
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.25s ease-out;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .author-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    img {
      width: 52px;
      height: 52px;
      margin-right: 18px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .author-info {
    display: flex;
    flex-direction: column;
    .datetime {
      color: #bf825a;
    }
    .author-name,
    .datetime {
      font-size: 1rem;
      line-height: 1.5em;
      font-weight: 600;
    }
    .author-name {
      color: #2e2b26;
      margin-bottom: 2px;
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 100px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
      }
      img {
        height: 50vh;
      }
    }
    .single-post {
      &:nth-child(even) {
        .inside-post {
          flex-direction: column;
        }
      }
      &:not(:last-child) {
        .inside-post {
          &:after {
            left: 20px;
            width: calc(100% - 40px);
          }
        }
      }
    }
    .inside-post {
      flex-direction: column;
      padding-bottom: 60px;
      margin-bottom: 60px;
      .image-col {
        order: -1;
        margin-bottom: 30px;
      }
      .content-col,
      .image-col {
        padding: 0 20px;
        width: 100%;
      }
      .content-col {
        .author-wrapper {
          margin-bottom: 28px;
          img {
            width: 48px;
            height: 48px;
          }
        }
        h3 {
          font-size: 1.25rem;
          margin-bottom: 20px;
        }
        .short-text {
          font-size: 0.875rem;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 140px;
      height: calc(40vh - 68px);
      img {
        height: 100%;
      }
      .image-mobile {
        display: block;
      }
      .image-desktop {
        display: none;
      }
      h1 {
        margin: auto 0 -80px 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
      }
    }
    .image-col {
      display: flex;
      img {
        height: 250px;
        width: 100%;
      }
      a,
      span {
        width: 100%;
      }
    }
  }
  @media (max-width: 400px) {
    .image-col {
      img {
        height: 200px;
      }
    }
  }
`;

export default function PostEn() {
  const [postData, setPost] = useState(null);

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"] | order(order asc)  {
      titleEN,
      slug,
      publishedAt,
      descriptionEN,
      "name" : author->name,
      "authorImage": author->image,
      mainImage {
        asset->{
          _id,
          url
        },
        alt
      }
    }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  return (
    <StyledPosts>
      <Helmet>
        <title>News</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/aktualnosci">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/posts">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>News</h1>
        <img className="image-mobile" src={postsImageMobile} alt="News" />
        <img className="image-desktop" src={postsImage} alt="News" />
      </div>
      <div className="posts-wrapper">
        {postData &&
          postData.map((post, index) => (
            <article className="single-post">
              <div key={index} className="inside-post">
                <div className="content-col">
                  <Link
                    to={"/aktualnosci/" + post.slug.current}
                    key={post.slug.current}
                  >
                    <h3>{post.titleEN}</h3>
                  </Link>
                  <div className="author-wrapper">
                    {post.authorImage && (
                       <figure>
                         <img
                            src={urlFor(post?.authorImage).url()}
                            alt={post.name}
                         />
                       </figure>
                    )}
                    <div className="author-info">
                      <span className="author-name">{post.name}</span>
                      <span className="datetime">{post.publishedAt}</span>
                    </div>
                  </div>
                  <p className="short-text">{post.descriptionEN}</p>
                  <Link
                    to={"/aktualnosci/" + post.slug.current}
                    key={post.slug.current}
                  >
                    <span className="read-more">Read more</span>
                  </Link>
                </div>
                <div className="image-col">
                  <Link
                    to={"/aktualnosci/" + post.slug.current}
                    key={post.slug.current}
                  >
                    {post.mainImage && (
                       <LazyLoadImage
                          alt={post?.mainImage.alt}
                          src={post?.mainImage.asset.url}
                          effect="opacity"
                       />
                    )}
                  </Link>
                </div>
              </div>
            </article>
          ))}
      </div>
    </StyledPosts>
  );
}
