import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import sanityClient from "../client.js";
import { Link, NavLink } from "react-router-dom";
import concertsImage from "../images/concerts-image.jpg";
import concertsImageMobile from "../images/concerts-image-mobile.jpg";
import arrowRight from "../images/arrow-right-small.svg";
import styled from "styled-components";
import imageUrlBuilder from "@sanity/image-url";
import homeImage from "../images/home-image.jpg";
import pageLogo from "../images/airis-logo-small.png";
import BlockContent from "@sanity/block-content-to-react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const StyledPosts = styled.section`
  background: #000;
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 73px 0 160px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
    }
    .image-mobile {
      display: none;
    }
    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      margin-bottom: 160px;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
    }
  }
  .posts-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  figure {
    margin: 0;
  }

  .inside-post {
    display: flex;
    padding-bottom: 80px;
    margin-bottom: 80px;
    position: relative;
    .content-col,
    .image-col {
      padding: 0 32px;
      width: 50%;
    }
  }
  .image-col {
    position: relative;
    .datetime {
      position: absolute;
      top: 20px;
      right: 52px;
      padding: 8px;
      background: #fff;
      span {
        font-size: 0.875rem;
        line-height: 1em;
        color: #2e2b26;
        margin: 0;
        font-weight: 700;
      }
    }
  }
  a {
    color: #989898;
    word-break: break-all;
    white-space: normal;
  }
  .content-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    h3 {
      font-weight: 700;
      color: #fff;
      font-size: 1.5rem;
      line-height: 1.5em;
      margin-bottom: 32px;
      transition: 0.25s ease-out;
    }
  }
  .short-text {
    font-size: 1rem;
    line-height: 1.5em;
    color: #bf825a;
    margin-bottom: 32px;
    text-align: justify;
    font-weight: 400;
    + a {
      margin-left: auto;
    }
  }
  .block-content {
    > * {
      color: #fff;
      font-size: 0.9375rem;
      line-height: 1.6em;
    }
  }
  .image-col {
    figure {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 100px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
      }
      img {
        height: 50vh;
      }
    }
    .single-post {
      .inside-post {
        flex-direction: column;
      }
    }
    .inside-post {
      flex-direction: column;
      padding-bottom: 60px;
      margin-bottom: 60px;
      .image-col {
        order: -1;
        margin-bottom: 30px;
      }
      .content-col,
      .image-col {
        padding: 0 20px;
        width: 100%;
      }
      .image-col {
        .datetime {
          top: 12px;
          right: 32px;
          span {
            font-size: 0.75rem;
          }
        }
      }
      .content-col {
        h3 {
          font-size: 1.25rem;
          margin-bottom: 20px;
        }
        .short-text {
          font-size: 0.875rem;
          margin-bottom: 20px;
        }
      }
      .block-content {
        > * {
          font-size: 0.875rem;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 140px;
      height: calc(40vh - 68px);
      img {
        height: 100%;
      }
      .image-mobile {
        display: block;
      }
      .image-desktop {
        display: none;
      }
      h1 {
        margin: auto 0 -80px 0;
        color: #fff;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }
`;

export default function ConcertEn() {
  const [concertData, setConcert] = useState(null);

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "concert"] | order(order asc) {
      titleEN,
      slug,
      publishedAt,
      descriptionEN,
      bodyEN, 
      mainImage {
        asset->{
          _id,
          url
        },
        alt
      }
    }`
      )
      .then((data) => setConcert(data))
      .catch(console.error);
  }, []);

  return (
    <StyledPosts>
      <Helmet>
        <title>Concerts</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/koncerty">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/concerts">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Concerts</h1>
        <img
          className="image-mobile"
          src={concertsImageMobile}
          alt="Concerts"
        />
        <img className="image-desktop" src={concertsImage} alt="Concerts" />
      </div>
      <div className="posts-wrapper">
        {concertData &&
          concertData.map((concert, index) => (
            <article key={index} className="single-post">
              <div className="inside-post">
                <div className="image-col">
                  <div className="datetime">
                    <span>{concert.publishedAt}</span>
                  </div>
                  {concert?.mainImage && (
                     <LazyLoadImage
                        alt={concert.mainImage.alt}
                        src={concert.mainImage.asset.url}
                        effect="opacity"
                     />
                  )}
                </div>
                <div className="content-col">
                  <h3>{concert.titleEN}</h3>
                  <div className="short-text">
                    <BlockContent
                      blocks={concert.descriptionEN}
                      projectId="gysyst6l"
                      dataset="production"
                    />
                  </div>
                  <div className="block-content">
                    <BlockContent
                      blocks={concert.bodyEN}
                      projectId="gysyst6l"
                      dataset="production"
                    />
                  </div>
                </div>
              </div>
            </article>
          ))}
      </div>
    </StyledPosts>
  );
}
