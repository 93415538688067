import { createGlobalStyle } from "styled-components";
import arrowBottom from "./images/arrow-bottom-white.svg";
import iconFb from "./images/icon-fb.svg";
import iconSp from "./images/icon-sp.svg";
import iconYt from "./images/icon-yt.svg";
import iconIn from "./images/icon-in.svg";
import iconCustom from "./images/icon-custom.svg";

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
  }
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  body {
      line-height: 1;
  }
  ol, ul {
      list-style: none;
  }
  blockquote, q {
      quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }
body {
  font-size: 16px;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  width: 100vw;
  overflow-x: hidden;
}
*, *::after, *::before {
  box-sizing: border-box
}

#lg-download {
    display: none !important;
  }

#lg-counter {
    font-weight: 500;
  }

.social-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  z-index: 10;
  li {
    margin: 0 0 32px 0;
    width: 20px;
    height: 20px;
    display: flex;
    a {
      text-decoration: none;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      position: relative;
      display: block;
      &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          transition: 0.2s ease-in-out;
          z-index: 2;
      display: block;

        }
        &:hover {
          &:before {
            transform: scale(1.2);
          }
        }
    }
    &.link-facebook {
      a {
        &:before {
          background: url(${iconFb}) no-repeat center center;
          background-size: contain;
        }
      }
    }
    &.link-spotify {
      a {
        &:before {
          background: url(${iconSp}) no-repeat center center;
          background-size: contain;
        }
      }
    }
    &.link-youtube {
      a {
        &:before {
          background: url(${iconYt}) no-repeat center center;
          background-size: contain;
        }
      }
    }
    &.link-custom {
      a {
        &:before {
          background: url(${iconCustom}) no-repeat center center;
          background-size: contain;
        }
      }
    }
    &.link-instagram {
      a {
        &:before {
          background: url(${iconIn}) no-repeat center center;
          background-size: contain;
        }
      }
    }
  }

}

.main-navbar {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0 30px;
  z-index: 999;
  display: flex;
  align-items: center;
  .brand-logo {
          margin-right: 2rem;
          img {
            max-width: 350px;
          }
  }
  .brand-logo-small {
    margin-right: 2rem;
    padding: .75rem 0 .25rem;
    cursor: pointer;
    img {
      max-height: 55px;
    }
  }
  &.active {
   .nav-links-wrapper {
      opacity: 1;
    pointer-events: all;
    visibility: visible;
    ul {
      li {
        opacity: 1;
      }
    }
    
    .social-nav {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    flex-direction: row;
    li {
      margin: 0 1.25rem;
    }
  }
   }
  }
.nav-links-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  transition: all 0.25s ease-out;
  pointer-events: none;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  img {
      position: absolute;
      width: 130vw;
      height: 130vh;
      left: -15vw;
      top: -15vh;
      object-fit: cover;
      z-index: -1;
      filter: blur(10px);
      object-position: 35% 50%;

  }
  .nav-links {
      width: 100%;
      height: 100%;
      z-index: 4;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }
  li {
    margin: 0 0 2.5rem 0;
    pointer-events: all;
    text-align: center;
  }
  li,
  a,
  button {
    color: #fff;
    font-size: 1.375rem;
    pointer-events: all;
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    transition: color .2s ease-out;
    &:hover {
        color: #BF825A;
    }
  }
  .btn-media {
    position: relative;
    &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    left: -2rem;
    background: #fff;
    z-index: 3;
  }
  &:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    left: -2.75rem;
    background: #fff;
    z-index: 1;
  }
  &.active {
    margin-bottom: 1.5rem;
    &:after {
      display: none
    }
  }
  }
  .media-wrapper {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    transition: all .1s ease-out;
    display: flex;
    flex-direction: column;
    margin: 0;
    a,button {
      margin-bottom: 1.5rem;
      position: relative;
      font-size: 1.25rem;
      &:before {
        content: "";
        position: absolute;
        width: 0.875rem;
        height: 0.875rem;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
        left: -1.875rem;
        background: url(${arrowBottom}) no-repeat center center;
        background-size: contain;
        transition: 0.25s ease-out;
      }
    }
    &.active {
      opacity: 1;
      max-height: 300px;
      pointer-events: all;
      visibility: visible;
      margin: 0 0 1rem 0;
      transition: all .2s ease-out;
    }
  }
  .second-media {
    margin-bottom: 0;
  }
}
.lang-switcher {
    margin-left: auto;
    display: flex;
    margin-right: 20px;
    li {
        margin-right: 20px;
          &.lang-pl {
              position: relative;
              &:after {
                  content: '/';
                  font-size: .875rem;
                  line-height: 1.2em;
                  color: #fff;
                  right: -12px;
                  position: absolute;
                  font-weight: 400;
              }
          }
        a, button {
            color: #BF825A;
            font-size: 1rem;
            font-weight: 700;
            text-decoration: none;
            transition: all .2s ease-out;
            padding: 0;
            border: none;
            background: transparent;
            outline: none;
            cursor: pointer;
            &:hover {
                color: #fff;
            }
        }
    }
}
.burger {
  position: relative;
  z-index: 10;
  pointer-events: all;
  cursor: pointer;
  div {
    width: 26px;
    height: 2px;
    background-color: #bf825a;
    margin: 6px 0;
    transition: all 0.2s ease-out;
  }
  &.active {
    div {
      &:nth-child(1) {
        transform: rotate(-45deg) translate(-4px, 4px);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(45deg) translate(-7px, -7px);
      }
    }
  }
}




@media (max-width: 1400px) {
    .brand-logo {
      img {
        max-width: 275px;
        height: auto;
      }
    }
}

@media (max-width: 991px) {
    
  .nav-links {
    li {
      margin-bottom: 2rem;
      button,
      a {
        font-size: 1rem;
      }
    }
  }
    .nav-links-wrapper {
      .media-wrapper {
        margin-bottom: 0;
        &.active {
          margin-bottom: 0;
        }
      a,button {
        font-size: 1rem;
      }
    }
      .btn-media {
    position: relative;
    font-size: 1.125rem;
    &:after {
    height: 1rem;
    left: -1.5625rem;
  }
  &:before {
    width: 1rem;
    left: -2rem;
  }
  &.active {
    margin-bottom: 1.5rem;
    &:after {
      display: none
    }
  }
  }
    }
   
}

@media (max-width: 767px) {
  padding: 0 20px 0 15px;
  overflow: hidden;
  .nav-links-wrapper {
    img {
      position: absolute;
      width: 110vw;
      height: 110vh;
      left: -5vw;
      top: -5vh;
      object-position: 42% 50%;
  }
  .social-nav {
      display: flex;
    }
  }
    .brand-logo {
      img {
        max-width: 250px;
      }
    }
}
@media(max-width: 575px) {
 
  .brand-logo-small {
    img {
    max-height: 50px;
    }
  }
  &.active {
      .lang-switcher {
          opacity: 1;
    pointer-events: all;
    visibility: visible;
    z-index: 3;
    margin: 0;
    transition: all 0.25s ease-out;
    li { 
        a {
            font-size: 1.125rem;
        }
    }
    .lang-en {
        margin: 0;
    }
      }
  }
  .lang-switcher {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30px;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: all 0.25s ease-out;
  }
  .burger {
      margin-left: auto;
  }
}

@media (max-width: 359px) {
    .brand-logo {
      img {
        max-width: 200px;
      }
    }
}
@media (max-width: 300px) {
    .brand-logo {
      img {
        max-width: 150px;
      }
    }
}

@media(max-height: 650px) {
  .nav-links {
    li {
      margin-bottom: 1.25rem;
      button,
      a {
        font-size: .875rem;
      }
    }
  }
    .nav-links-wrapper {
      .media-wrapper {
        margin-bottom: 0;
        &.active {
          margin-bottom: 0;
        }
      a,button {
        font-size: .8125rem;
        margin-bottom: 1.25rem;
      }
    }
      .btn-media {
    position: relative;
    font-size: .875rem;

    
  &.active {
    margin-bottom: 1rem;
    
  }
  }
    }
}
}
`;
