import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import sanityClient from "../client.js";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import homeImage from "../images/home-image.jpg";
import pageLogo from "../images/airis-logo-small.png";
import BlockContent from "@sanity/block-content-to-react";
import repertoireImage from "../images/repertuar.jpeg";
import galleryImage12 from "../images/repertoire-desktop.jpeg";

const StyledRepertoire = styled.section`
  .brand-logo-small,
  .nav-links li {
    &.d-none {
      display: none !important;
    }
  }
  .lang-switcher {
    li {
      button {
        &.d-none {
          pointer-events: none;
        }
      }
    }
  }
  .image-mobile {
    display: none;
  }
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 73px 0 60px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 60%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
    }
    .image-desktop {
      object-position: 50% 100%;
    }
    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      margin-bottom: 160px;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      &.d-none {
        display: none;
      }
    }
  }
  .content-wrapper {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  .block-content {
    > * {
      color: #000;
      font-size: 1rem;
      line-height: 1.5em;
    }
    &.d-none {
      display: none;
    }
    > * {
      text-align: justify;
    }
    h1 {
      color: #000;
      font-size: 2.75rem;
      line-height: 1.3em;
      font-weight: 600;
      margin: 0 0 50px;
      text-align: left;
    }
    h2 {
      color: #000;
      font-size: 2rem;
      font-weight: normal;
      line-height: 1.3em;
      margin: 50px 0 50px;
      padding: 40px 0 0;
      font-weight: 600;
      text-align: left;
    }
    h3 {
      color: #000;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.3em;
      margin: 0 0 50px;
      padding: 40px 0 0;
      font-weight: 600;
      text-align: left;
    }
    p,
    li,
    blockquote {
      color: #000;
      font-size: 1rem;
      line-height: 1.5em;
    }
    ul {
      list-style: disc;
      padding-left: 16px;
      li {
        margin-bottom: 15px;
      }
    }
    p,
    blockquote {
      margin: 0 0 20px;
    }
    a {
      color: #bf825a;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.25s ease-out;
      &:hover {
        color: #89502a;
      }
    }
    strong {
      font-weight: 700;
    }
    em,
    blockquote {
      font-style: italic;
    }
    img {
      max-width: 100%;
      height: auto;
      margin: 0 0 30px 0;
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
      }
      img {
        height: 50vh;
      }
    }
    .block-content {
      h1 {
        font-size: 1.875rem;
        margin: 0 0 50px;
      }
      h2 {
        font-size: 1.5rem;
        margin: 0 0 50px;
        padding: 40px 0 0;
      }
      h3 {
        font-size: 1.125rem;
        margin: 0 0 50px;
        padding: 40px 0 0;
      }
      p,
      li,
      blockquote {
        font-size: 16px;
      }
      ul {
        li {
          margin-bottom: 12px;
        }
      }
      p,
      blockquote {
        margin: 0 0 20px;
      }
    }
  }
  @media (max-width: 768px) {
    .image-desktop {
      display: none;
    }
    .image-mobile {
      display: block;
    }
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      height: calc(40vh - 68px);
      margin-bottom: 130px;
      img {
        height: 100%;
      }
      h1 {
        margin: auto 0 -80px 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
      }
    }
    .block-content {
      h1 {
        font-size: 1.5rem;
        margin: 0 0 40px;
      }
      h2 {
        font-size: 1.35rem;
        margin: 0 0 40px;
      }
      h3 {
        margin: 0 0 40px;
      }
      p,
      blockquote {
        margin: 0 0 16px;
      }
      p,
      li,
      blockquote {
        font-size: 0.875rem;
      }
    }
  }
`;

export default function Repertoire() {
  const [repertoireData, setRepertoire] = useState(null);

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isEnglish, setEnglish] = useState("true");
  const ToggleEnglish = () => {
    setEnglish(!isEnglish);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "repertoire"]{
      title,
      body, 
      bodyEN, 
    }`
      )
      .then((data) => setRepertoire(data))
      .catch(console.error);
  }, []);

  return (
    <StyledRepertoire>
      <Helmet>
        <title>Repertuar</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <button
              type="button"
              className={isEnglish ? "d-none" : ""}
              onClick={ToggleEnglish}
            >
              PL
            </button>
          </li>
          <li className="lang-en">
            <button
              type="button"
              className={isEnglish ? "" : "d-none"}
              onClick={ToggleEnglish}
            >
              EN
            </button>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li className={isEnglish ? "second-media" : "second-media d-none"}>
              <div
                className={isVisible ? "media-wrapper" : "media-wrapper active"}
              >
                <NavLink to="/filmy">Filmy</NavLink>
                <NavLink to="/galeria">Zdjęcia</NavLink>
              </div>
            </li>
            <li className={isEnglish ? "d-none second-media" : "second-media"}>
              <div
                className={isVisible ? "media-wrapper" : "media-wrapper active"}
              >
                <NavLink to="/movies">Movies</NavLink>
                <NavLink to="/galeria">Photos</NavLink>
              </div>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/repertoire">Repertoire</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1 className={isEnglish ? "" : "d-none"}>Repertuar</h1>
        <h1 className={isEnglish ? "d-none" : ""}>Repertoire</h1>
        <img
          className="image-desktop"
          src={galleryImage12}
          alt="Repertuar Airis String Quartet"
        />
        <img
          className="image-mobile"
          src={repertoireImage}
          alt="Repertuar Airis String Quartet"
        />
      </div>
      <div className="content-wrapper">
        {repertoireData &&
          repertoireData.map((repertoire, index) => (
            <article key={index} className="single-repertoire">
              <div
                className={
                  isEnglish ? "block-content" : " block-content d-none"
                }
              >
                <BlockContent
                  blocks={repertoire.body}
                  projectId="gysyst6l"
                  dataset="production"
                />
              </div>
              <div
                className={
                  isEnglish ? "block-content d-none" : " block-content"
                }
              >
                <BlockContent
                  blocks={repertoire.bodyEN}
                  projectId="gysyst6l"
                  dataset="production"
                />
              </div>
            </article>
          ))}
      </div>
    </StyledRepertoire>
  );
}
