import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import styled from "styled-components";
import homeImage from "../images/home-image.jpg";
import pageLogo from "../images/airis-logo-small.png";
import { Link, NavLink } from "react-router-dom";

const StyledArticle = styled.article`
  max-width: 1238px;
  margin: 0 auto;
  padding: 125px 32px 100px;
  .brand-logo-small,
  .nav-links li {
    &.d-none {
      display: none !important;
    }
  }
  .lang-switcher {
    li {
      button {
        &.d-none {
          pointer-events: none;
        }
      }
    }
  }
  header {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 80px;
    .image-col {
      width: 100%;
      height: 100%;
      figure {
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .heading-col {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /* align-items: center; */
      padding-top: 32px;
      h1,
      h2 {
        font-weight: 700;
        color: #2e2b26;
        font-size: 2rem;
        line-height: 1.3em;
        margin-bottom: 32px;
        &.d-none {
          display: none;
        }
      }
    }
    .author-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      img {
        width: 52px;
        height: 52px;
        margin-right: 18px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .datetime {
      color: #bf825a;
      margin-bottom: 10px;
    }
    .author-name,
    .datetime {
      font-size: 1rem;
      line-height: 1.5em;
      font-weight: 600;
    }
    .author-name {
      color: #2e2b26;
      margin-bottom: 2px;
    }
  }
  .main-content {
    &.d-none {
      display: none;
    }
    > * {
      text-align: justify;
    }
    h1 {
      color: #000;
      font-size: 2.75rem;
      line-height: 1.3em;
      font-weight: 600;
      margin: 0 0 50px;
    }

    h2 {
      color: #000;
      font-size: 2rem;
      font-weight: normal;
      line-height: 1.3em;
      margin: 0 0 50px;
      padding: 40px 0 0;
      font-weight: 600;
    }

    h3 {
      color: #000;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.3em;
      margin: 0 0 50px;
      padding: 40px 0 0;
      font-weight: 600;
    }

    p,
    li,
    blockquote {
      color: #000;
      font-size: 1.125rem;
      line-height: 1.5em;
    }

    ul {
      list-style: disc;
      padding-left: 16px;
      li {
        margin-bottom: 15px;
      }
    }

    p,
    blockquote {
      margin: 0 0 24px;
    }

    a {
      color: #bf825a;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.25s ease-out;
      white-space: normal;
      word-break: break-all;
      &:hover {
        color: #89502a;
      }
    }

    strong {
      font-weight: 700;
    }

    em,
    blockquote {
      font-style: italic;
    }

    img {
      max-width: 100%;
      height: auto;
      margin: 0 0 30px 0;
    }
  }
  @media (max-width: 991px) {
    padding: 75px 20px 60px;
    header {
      margin-bottom: 50px;
      .image-col {
        width: calc(100% + 40px);
        height: 100%;
        margin-left: -20px;
      }
      .heading-col {
        h1,
        h2 {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }
      }
      .author-wrapper {
        margin-bottom: 20px;
        img {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }
      }
      .author-name,
      .datetime {
        font-size: 0.875rem;
      }
    }
    .main-content {
      h1 {
        font-size: 1.875rem;
        margin: 0 0 50px;
      }
      h2 {
        font-size: 1.5rem;
        margin: 0 0 50px;
        padding: 40px 0 0;
      }
      h3 {
        font-size: 1.25rem;
        margin: 0 0 50px;
        padding: 40px 0 0;
      }
      p,
      li,
      blockquote {
        font-size: 16px;
      }
      ul {
        li {
          margin-bottom: 12px;
        }
      }
      p,
      blockquote {
        margin: 0 0 20px;
      }
    }
  }
  @media (max-width: 575px) {
    header {
      .heading-col {
        h1,
        h2 {
          font-size: 1.25rem;
        }
      }
    }
    .main-content {
      h1 {
        font-size: 1.5rem;
        margin: 0 0 40px;
      }
      h2 {
        font-size: 1.35rem;
        margin: 0 0 40px;
      }
      h3 {
        margin: 0 0 40px;
      }
      p,
      blockquote {
        margin: 0 0 16px;
      }
    }
  }
`;

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function SingleProject() {
  const [singleProject, setSingleProject] = useState(null);
  const { slug } = useParams();

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isEnglish, setEnglish] = useState("true");
  const ToggleEnglish = () => {
    setEnglish(!isEnglish);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
      title,
      titleEN,
      _id, 
      slug, 
      mainImage {
        asset->{
          _id,
          url
        }
      },
      body,
      bodyEN,
    }`
      )
      .then((data) => setSingleProject(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleProject) return <div></div>;

  return (
    <StyledArticle>
      <Helmet>
        <title>{singleProject.title}</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink
          to="/"
          className={isEnglish ? "brand-logo-small d-none" : "brand-logo-small"}
        >
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <NavLink
          to="/home-en"
          className={
            isEnglish ? "brand-logo-small" : " brand-logo-small d-none"
          }
        >
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <button
              type="button"
              className={isEnglish ? "d-none" : ""}
              onClick={ToggleEnglish}
            >
              PL
            </button>
          </li>
          <li className="lang-en">
            <button
              type="button"
              className={isEnglish ? "" : "d-none"}
              onClick={ToggleEnglish}
            >
              EN
            </button>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/">Biography</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li className={isEnglish ? "second-media" : "second-media d-none"}>
              <div
                className={isVisible ? "media-wrapper" : "media-wrapper active"}
              >
                <NavLink to="/filmy">Filmy</NavLink>
                <NavLink to="/galeria">Zdjęcia</NavLink>
              </div>
            </li>
            <li className={isEnglish ? "d-none second-media" : "second-media"}>
              <div
                className={isVisible ? "media-wrapper" : "media-wrapper active"}
              >
                <NavLink to="/movies">Movies</NavLink>
                <NavLink to="/galeria">Photos</NavLink>
              </div>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li className={isEnglish ? "" : "d-none"}>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
            <li className={isEnglish ? "d-none" : ""}>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <header>
        <div className="heading-col">
          <span className="datetime">{singleProject.publishedAt}</span>
          <h1 className={isEnglish ? "" : "d-none"}>{singleProject.title}</h1>
          <h2 className={isEnglish ? "d-none" : ""}>{singleProject.titleEN}</h2>
        </div>
        <div className="image-col">
          {singleProject?.mainImage && (
             <figure>
               <img
                  src={singleProject.mainImage.asset.url}
                  alt={singleProject.title}
               />
             </figure>
          )}
        </div>
      </header>
      <div className={isEnglish ? "main-content" : " main-content d-none"}>
        <BlockContent
           blocks={singleProject.body}
          projectId="gysyst6l"
          dataset="production"
        />
      </div>
      <div className={isEnglish ? "main-content d-none" : " main-content"}>
        <BlockContent
          blocks={singleProject.bodyEN}
          projectId="gysyst6l"
          dataset="production"
        />
      </div>
    </StyledArticle>
  );
}
