import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PT from "prop-types";
import ReactDOM from "react-dom";
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import galleryImage from "../images/gallery/gallery-1.jpg";
import galleryImage2 from "../images/gallery/gallery-2.jpg";
import galleryImage3 from "../images/gallery/gallery-3.jpg";
import galleryImage4 from "../images/gallery/gallery-4.jpg";
import galleryImage5 from "../images/gallery/gallery-5.jpg";
import galleryImage6 from "../images/gallery/gallery-6.jpg";
import galleryImage7 from "../images/gallery/gallery-7.jpg";
import galleryImage8 from "../images/gallery/gallery-8.jpg";
import galleryImage9 from "../images/gallery/gallery-9.jpg";
import galleryImage10 from "../images/gallery/gallery-10.jpg";
import galleryImage11 from "../images/gallery/gallery-11.jpg";
import galleryImage12 from "../images/gallery/gallery-12.jpg";
import galleryImage13 from "../images/gallery/gallery-13.jpg";
import galleryImage14 from "../images/gallery/gallery-14.jpg";
import galleryImage15 from "../images/gallery/gallery-15.jpg";
import galleryImage16 from "../images/gallery/gallery-16.jpg";
import galleryImage17 from "../images/gallery/gallery-17.jpg";
import galleryImage18 from "../images/gallery/gallery-18.jpg";
import galleryImage19 from "../images/gallery/gallery-19.jpg";
import galleryImage20 from "../images/gallery/gallery-20.jpg";
import galleryImage21 from "../images/gallery/gallery-21.jpg";
import galleryImage22 from "../images/gallery/gallery-22.jpg";
import galleryImage23 from "../images/gallery/gallery-23.jpg";
import galleryImage24 from "../images/gallery/gallery-24.JPG";
import galleryImage25 from "../images/gallery/gallery-25.jpeg";
import galleryImage26 from "../images/gallery/gallery-26.jpeg";
import galleryImage27 from "../images/gallery/gallery-27.jpeg";
import galleryImage28 from "../images/gallery/gallery-28.jpeg";
import galleryImage29 from "../images/gallery/gallery-29.jpg";
import galleryImage30 from "../images/gallery/gallery-30.jpg";
import galleryImage31 from "../images/gallery/gallery-31.jpg";
import galleryImage32 from "../images/gallery/gallery-32.jpg";
import galleryImage33 from "../images/gallery/gallery-33.jpg";
import galleryImage34 from "../images/gallery/gallery-34.jpg";
import galleryImage35 from "../images/gallery/gallery-35.jpg";
import galleryImage36 from "../images/gallery/gallery-36.jpg";
import galleryImage37 from "../images/gallery/gallery-37.jpg";

const GROUP2 = [
  galleryImage,
  galleryImage2,
  galleryImage4,
  galleryImage5,
  galleryImage8,
  galleryImage9,
  galleryImage10,
  galleryImage25,
  galleryImage26,
  galleryImage27,
  galleryImage28,
  galleryImage11,
  galleryImage12,
  galleryImage13,
  galleryImage14,
  galleryImage15,
  galleryImage16,
  galleryImage17,
  galleryImage18,
  galleryImage19,
  galleryImage20,
  galleryImage21,
  galleryImage22,
  galleryImage23,
  // galleryImage24,
  galleryImage29,
  galleryImage30,
  galleryImage31,
  galleryImage32,
  galleryImage33,
  galleryImage34,
  galleryImage35,
  galleryImage36,
  galleryImage37,
];

const PhotoItem = ({ image, thumb, group }) => (
  <div className="single-div">
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} />
    </LightgalleryItem>
  </div>
);

PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
};

const StyledGallery = styled.section`
  margin: 160px 0 120px;
  .gallery-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 60px 10px;
    .single-div {
      position: relative;
      width: 33.33%;
      height: 300px;
      overflow: hidden;
      div {
        overflow: hidden;
        margin: 20px 10px;
        height: 100%;
      }
    }
    img {
      height: 100%;
      width: 100%;
      object-position: 50% 0;
      object-fit: cover;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .authors {
    max-width: 1238px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    h5 {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0 0 40px 0;
      &.d-none {
        display: none;
      }
    }
    ul {
      li {
        font-size: 1rem;
        font-weight: 500;
        margin: 0 0 15px 0;
      }
    }
  }

  @media (max-width: 1199px) {
    .gallery-wrapper {
      .single-div {
        height: 250px;
      }
    }
  }
  @media (max-width: 991px) {
    margin: 100px 0 60px;
    .gallery-wrapper {
      .single-div {
        width: 50%;
        height: 300px;
      }
    }
  }
  @media (max-width: 767px) {
    .gallery-wrapper {
      .single-div {
        height: 200px;
      }
    }
    .authors {
      h5 {
        font-size: 1.125rem;
      }
      ul {
        li {
          font-size: 0.9375rem;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .gallery-wrapper {
      .single-div {
        height: 175px;
        div {
          margin: 10px 5px;
        }
      }
    }
  }
  @media (max-width: 450px) {
    .gallery-wrapper {
      .single-div {
        height: 150px;
      }
    }
  }
  @media (max-width: 400px) {
    .gallery-wrapper {
      .single-div {
        height: 125px;
      }
    }
  }
`;

export default function Bio() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  const [isEnglish, setEnglish] = useState("true");
  const ToggleEnglish = () => {
    setEnglish(!isEnglish);
  };

  return (
    <StyledGallery>
      <Helmet>
        <title>Galeria</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <button
              type="button"
              className={isEnglish ? "d-none" : ""}
              onClick={ToggleEnglish}
            >
              PL
            </button>
          </li>
          <li className="lang-en">
            <button
              type="button"
              className={isEnglish ? "" : "d-none"}
              onClick={ToggleEnglish}
            >
              EN
            </button>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/filmy">Filmy</NavLink>
              <NavLink to="/galeria">Zdjęcia</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>

      <LightgalleryProvider
        onBeforeOpen={() => console.info("onBeforeOpen")}
        onAfterOpen={() => console.info("onAfterOpen")}
        onSlideItemLoad={() => console.info("onSlideItemLoad")}
        onBeforeSlide={() => console.info("onBeforeSlide")}
        onAfterSlide={() => console.info("onAfterSlide")}
        onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
        onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
        onDragstart={() => console.info("onDragstart")}
        onDragmove={() => console.info("onDragmove")}
        onDragend={() => console.info("onDragend")}
        onSlideClick={() => console.info("onSlideClick")}
        onBeforeClose={() => console.info("onBeforeClose")}
        onCloseAfter={() => console.info("onCloseAfter")}
      >
        <div className="gallery-wrapper">
          {GROUP2.map((p, idx) => (
            <PhotoItem key={idx} image={p} group="group2" />
          ))}
        </div>
      </LightgalleryProvider>
      <div className="authors">
        <h5 className={isEnglish ? "" : "d-none"}>Autorzy zdjęć:</h5>
        <h5 className={isEnglish ? "d-none" : ""}>Photographers:</h5>
        <ul>
          <li>Bartek Barczyk (1-11)</li>
          <li>Edyta Dufaj (12-17, 23-24)</li>
          <li>Klaudyna Schubert (18-22)</li>
          <li>Piotr Markowski (25-33)</li>
        </ul>
      </div>
    </StyledGallery>
  );

  /*   return (
    <StyledGallery>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/aleksandra">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/">Filmy</NavLink>
              <NavLink to="/">Zdjęcia</NavLink>
            </li>
            <li>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li>
              <NavLink to="/">Dyskografia</NavLink>
            </li>
            <li>
              <NavLink to="/">Współpraca</NavLink>
            </li>
            <li>
              <NavLink to="/">Kontakt</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <ul className="gallery-wrapper">
        <Gallery>
          <li>
            <Item original={galleryImage} thumbnail={galleryImage}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage} />
              )}
            </Item>
          </li>
          <li>
            {" "}
            <Item original={galleryImage2} thumbnail={galleryImage2}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage2} />
              )}
            </Item>
          </li>
          <li>
            <Item original={galleryImage3} thumbnail={galleryImage3}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage3} />
              )}
            </Item>
          </li>
          <li>
            <Item original={galleryImage4} thumbnail={galleryImage4}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage4} />
              )}
            </Item>
          </li>
          <li>
            <Item original={galleryImage5} thumbnail={galleryImage5}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage5} />
              )}
            </Item>
          </li>
          <li>
            <Item original={galleryImage6} thumbnail={galleryImage6}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage6} />
              )}
            </Item>
          </li>
          <li>
            <Item original={galleryImage7} thumbnail={galleryImage7}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage7} />
              )}
            </Item>
          </li>
          <li>
            <Item original={galleryImage8} thumbnail={galleryImage8}>
              {({ ref, open }) => (
                <img ref={ref} onClick={open} src={galleryImage8} />
              )}
            </Item>
          </li>
        </Gallery>
      </ul>
    </StyledGallery>
  ); */
}
