import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import bioImage from "../images/ola-bio-main.jpg";
import bioFirstImage from "../images/ola-bio-3.jpeg";
import bioFirstMobile from "../images/ola-bio-mobile.jpg";
import bioSecondImage from "../images/ola-bio-main.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin: 73px 0 120px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 30%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      margin-bottom: 20px;
    }
    span {
      font-family: "Cormorant Garamond", serif;
      color: #fff;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 60px;
      z-index: 3;
    }
  }
  .mobile-image {
    display: none;
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Libre Baskerville", serif;
  }
  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 150px;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 8rem;
    margin-top: -5rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 16px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
    }
    &.download {
      border: none;
    }
  }

  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
      &:nth-child(odd) {
        .right-col {
          width: 40%;
        }
        .left-col {
          width: 60%;
        }
      }
      &:nth-child(even) {
        .right-col {
          width: 60%;
        }
        .left-col {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        font-size: 40px;
      }
      span {
        margin-bottom: 50px;
      }
      img {
        height: 50vh;
      }
    }
    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .button-wrapper {
      padding: 3rem 0 5rem;
    }
    .single-row {
      margin-bottom: 60px;
      &:nth-child(odd) {
        flex-direction: column;
        .left-col {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }
        .right-col {
          width: 100%;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .left-col {
          width: 100%;
        }
        .right-col {
          width: 100%;
          margin-bottom: 3rem;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 160px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
        object-position: 50% 50%;
      }

      h1 {
        margin: auto 0 0 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 0 1rem;
      }
      span {
        color: #000;
        font-size: 1.5rem;
        margin: 20px 0 -130px 0;
      }
    }
    .mobile-image {
      display: block;
    }
    .desktop-image {
      display: none;
    }
  }
`;

export default function Aleksandra() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledBio>
      <Helmet>
        <title>Aleksandra</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/aleksandra">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/aleksandra-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/filmy">Filmy</NavLink>
              <NavLink to="/galeria">Zdjęcia</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Aleksandra Czajor-Kowalska</h1>
        <span>I skrzypce</span>

        <img
          className="image-desktop"
          src={bioImage}
          alt="Aleksandra Czajor-Kowalska"
        />
      </div>
      <div className="bio-wrapper">
        <div className="single-row">
          <div className="right-col">
            <p>
              Naukę gry na skrzypcach rozpoczęła w wieku 6 lat w Szkole
              Muzycznej im. Fryderyka Chopina w Opolu, gdzie uczyła się w klasie
              skrzypiec Czesławy Banach, Danuty Sujaty a następnie Janusza
              Gajownika. W roku 2012 ukończyła z wyróżnieniem studia
              magisterskie na  <strong>Akademii Muzycznej w Krakowie</strong> w
              klasie prof. Piotra Tarcholika. Jest także absolwentką{" "}
              <strong>
                Uniwersytetu Muzycznego i Sztuk Scenicznych w Wiedniu
              </strong>{" "}
              w klasie kameralnej prof. Johanessa Meissla,  
              <strong>
                Królewskiego Konserwatorium Muzycznego w Antwerpii
              </strong>{" "}
              w klasie skrzypiec prof. Tatiany Samouil  i prof. Benjamina Braude
              oraz{" "}
              <strong>
                Akademii Muzycznej im. Karola Szymanowskiego w Katowicach
              </strong>{" "}
              w klasie kameralnej prof. Piotra Szumieła. Kształciła się także
              jako contract-student w holenderskim{" "}
              <strong>ArtEZ Conservatorium</strong> w Zwolle w klasie skrzypiec
              prof. Sary Kapustin i w klasie kameralnej prof. Jeroena Reulinga
              oraz na licznych kursach mistrzowskich w kraju i z granicą.
            </p>
            <p>
              W roku 2019 zakończyła <strong>studia doktorskie</strong> na
              Akademii Muzycznej w Krakowie i uzyskała tytuł{" "}
              <strong>doktora sztuki </strong>. Temat jej pracy doktorskiej
              brzmi: „Karl Amadeus Hartmann- wewnętrzna emigracja twórcy.
              Ekspresja i język muzyczny dzieł z czasów nazizmu na przykładzie{" "}
              <em>Concerto Funebre</em> na skrzypce i orkiestrę oraz kwartetów
              smyczkowych”. Wraz z orkiestrą smyczkową prowadzoną od pulpitu
              przez jej promotora prof. Piotra Tarcholika dokonała{" "}
              <strong>nagrania partii solowej</strong> w{" "}
              <em>Concerto Funebre</em> K. A. Hartmanna a wraz z{" "}
              <strong>Airis String Quartet</strong> - nagrań dwóch kwartetów
              smyczkowych. Płyta z kwartetami Hartmanna została później wydana
              przez CD Accord Music Edition i zdobyła bardzo entuzjastyczne
              recenzje.
            </p>
            <p>
              Aleksandra Czajor- Kowalska jest laureatką wielu{" "}
              <strong>stypendiów</strong>:{" "}
              <em> Stypendium Stowarzyszenia Parlament Dobroczynności</em>,
              Stypendium <em> Złota Spinka </em> Nowej Trybuny Opolskiej,  
              <em> Agrafka Muzyczna, Sapere Auso, Windish Kammermusik Preis</em>
              , Stypendium Rektora Akademii Muzycznej w Krakowie, stypendium
              <em> Młoda Polska </em> programów Ministerstwa Kultury i
              Dziedzictwa Narodowego takich jak{" "}
              <em> Zamówienia kompozytorskie </em> oraz{" "}
              <em> Artysta-rezydent</em>. Ma w swoim dorobku także liczne
              nagrody zdobyte na ogólnopolskich i międzynarodowych konkursach
              solowych i kameralnych, m.in.  
              <strong>III Nagroda</strong> na  
              <em>
                {" "}
                XXXI Ogólnopolskim Konkursie  Bachowski im. Stanisława Hajzera w
                Zielonej Górze
              </em>
              , tytuł <strong>finalistki</strong>{" "}
              <em>
                {" "}
                VI Konkursu Wykonawstwa Muzyki XX i XXI wieku w Radziejowicach
              </em>
              , <strong>II Nagroda</strong> na{" "}
              <em>
                {" "}
                Międzynarodowym Konkursie  Współczesnej Muzyki Kameralnej w
                Krakowie
              </em>
              , <strong>Grand prix</strong> na{" "}
              <em>
                {" "}
                International Master Competition for Musical Teachers w
                Warszawie
              </em>
              , <strong>III Nagroda</strong> na{" "}
              <em> Międzynarodowym Konkursie Muzycznym na Malcie</em>,{" "}
              <strong>
                Nagroda Specjalna za wykonanie dzieł XX- wiecznych
              </strong>{" "}
              na{" "}
              <em>
                {" "}
                II Międzynarodowym Konkursie kwartetów Smyczkowych im. K.
                Szymanowskiego w Katowicach
              </em>
                oraz{" "}
              <strong>
                Nagroda Specjalna za najlepsze wykonanie dzieła Krzysztofa
                Pendereckiego
              </strong>{" "}
              na{" "}
              <em>
                {" "}
                II Międzynarodowym Konkursie Kameralnym im. L. van Beethovena w
                Lusławicach
              </em>
              . 
            </p>
          </div>
          <div className="left-col">
            <figure>
              <img
                src={bioSecondImage}
                alt="Aleksandra Czajor-Kowalska"
                className="desktop-image"
              />
              <img
                src={bioFirstMobile}
                alt="Aleksandra Czajor-Kowalska"
                className="mobile-image"
              />
            </figure>
          </div>
        </div>
        <div className="single-row">
          <div className="right-col">
            <LazyLoadImage
              alt="Aleksandra Czajor-Kowalska"
              src={bioFirstImage}
              effect="opacity"
            />
          </div>
          <div className="left-col">
            <p>
              Aleksandra Czajor-Kowalska jest bardzo aktywną i otwartą artystką.
              Jej pasja i miłość do muzyki kameralnej znalazła największe
              spełnienie w <strong>Airis String Quartet</strong>, zespole,
              którego jest współzałożycielką i prymariuszką od 2008 roku.
              Ciekawość, otwartość na nowe wyzwania i potrzeba niezależności od
              zawsze determinowały jej rozwój i artystyczne wybory. Poza
              działalnością Airis String Quartet Aleksandra chętnie podejmuje
              współpracę z innymi muzykami, zespołami czy orkiestrami. Posiada
              spore doświadczenie jako{" "}
              <strong>koncertmistrz - dyrygent od pulpitu</strong> dzięki
              wieloletniej współpracy z{" "}
              <strong>Przemyską Orkiestrą Kameralną</strong>, z którą
              występowała również jako solistka . W swoim dorobku ma także duże
              doświadczenie jako <strong>muzyk orkiestrowy </strong> i
              współpracę z takimi zespołami jak NOSPR, Sinfonietta Cracovia,
              Orkiestra Akademii Beethovenowskiej, AUKSO, CORDACracovia,
              Filharmonia Krakowska oraz koncerty pod batutą dyrygentów: Sir
              Neville Mariner, Gabriel Chmura, Jacek Kasprzyk, Jerzy Maksymiuk,
              Michał Klauza, Łukasz Borowicz, Marek Moś, Mark Fitz-Gerald.
              Działalność orkiestrowa i kameralna zaowocowała udziałem w wielu
              nagraniach CD i DVD oraz koncertami w Niemczech, Francji, Anglii,
              Holandii, Belgii, Włoszech, Szwajcarii, Austrii, Czechach,
              Szwecji, Danii, Chinach i USA .
            </p>
            <p>
              Aleksandra skutecznie łączy aktywność koncertową z{" "}
              <strong>działalnością pedagogiczną</strong>, która jest dla niej
              równie ważna i inspirującą. Od 2017 roku Aleksandra pracuje w{" "}
              <strong>Katedrze Kameralistyki</strong> na{" "}
              <strong>
                {" "}
                Akademii Muzycznej im. Karola Szymanowskiego w Katowicach
              </strong>{" "}
              a od 2013 roku uczy skrzypiec i zespołów kameralnych w szkole
              muzycznej - obecnie w{" "}
              <strong>
                Państwowej Szkole Muzycznej I i II stopnia im. Fryderyka Chopina
                w Opolu
              </strong>
              . Aktywnie działa także jako popularyzatorka kameralistyki od
              najmłodszych lat edukacji artystycznej, zachęcając nauczycieli i
              uczniów do odnalezienia w niej radości i inspiracji do
              wszechstronnego rozwoju.
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <NavLink to="/biografia" className="read-more">
            Powrót
          </NavLink>
        </div>
      </div>
    </StyledBio>
  );
}
