import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import bioImage from "../images/malwina-bio-2.jpg";
import bioFirstImage from "../images/malwina-bio-2.jpg";
import bioFirstMobile from "../images/malwina-bio-mobile.jpg";
import bioSecondImage from "../images/malwina-bio.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin: 73px 0 80px;

    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 10%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      margin-bottom: 20px;
    }
    span {
      font-family: "Cormorant Garamond", serif;
      color: #fff;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 60px;
      z-index: 3;
    }
  }
  .mobile-image {
    display: none;
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Libre Baskerville", serif;
  }
  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 150px;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 8rem;
    margin-top: -5rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 16px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
    }
    &.download {
      border: none;
    }
  }

  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
      &:nth-child(odd) {
        .right-col {
          width: 40%;
        }
        .left-col {
          width: 60%;
        }
      }
      &:nth-child(even) {
        .right-col {
          width: 60%;
        }
        .left-col {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        font-size: 40px;
      }
      span {
        margin-bottom: 50px;
      }
      img {
        height: 50vh;
      }
    }
    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .button-wrapper {
      padding: 3rem 0 5rem;
    }
    .single-row {
      margin-bottom: 60px;
      &:nth-child(odd) {
        flex-direction: column;
        .left-col {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }
        .right-col {
          width: 100%;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .left-col {
          width: 100%;
        }
        .right-col {
          width: 100%;
          margin-bottom: 3rem;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 160px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
        object-position: 50% 50%;
      }

      h1 {
        margin: auto 0 0 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 0 1rem;
      }
      span {
        color: #000;
        font-size: 1.5rem;
        margin: 20px 0 -130px 0;
      }
    }
    .mobile-image {
      display: block;
    }
    .desktop-image {
      display: none;
    }
  }
`;

export default function MalwinaEN() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledBio>
      <Helmet>
        <title>Malwina</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/malwina">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/malwina-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Malwina Tkaczyk</h1>
        <span>viola</span>
        <img className="image-desktop" src={bioImage} alt="Malwina Tkaczyk" />
      </div>
      <div className="bio-wrapper">
        <div className="single-row">
          <div className="right-col">
            <p>
              Malwina started her musical education at the age of 7 playing
              violin at the{" "}
              <strong> Fryderyk Chopin Music School in Bytom </strong> and
              studied there for 12 years in a violin class of the prof. Marta
              Werner. At the age of 16 she also undertook studies in a viola
              class of the prof. Irena Kalinowska. It was a turning point in her
              education and moment when she decided to pursue her further
              instrumental career inseparably with viola. In 2011 she got
              accepted for a bachelor’s study at the{" "}
              <strong> Karol Szymanowski Academy of Music in Katowice </strong>{" "}
              where she graduated in 2014 with a distinction in viola class of
              the prof. Zygmunt Jochemczyk and prof. Elżbieta Mrozek-Loska. In
              2016 she graduated from a master’s degree in a viola class of
              Prof. Piotr Szumieł. Malwina continued her education at the
              Academy by attending post-graduate chamber music studies in a
              class of the prof. Arkadiusz Kubica and prof. Marek Moś . She took
              part in numerous solo, chamber music and orchestra masterclasses
              under direction of artists such as Alexander Gebert,
              Claus-Christian Schuster, Amiram Ganza, Tim Frederiksen, Royal
              String Quartet.
            </p>
            <p>
              Malwina is a <strong> laureate </strong> of many artistic
              <strong> scholarships </strong> e.g. <em> Young Poland </em>{" "}
              scholarship of the Ministry of Culture and National Heritage,
              scholarship from the President of her home city Świętochłowice,
              scholarship from the Minister of Education , scholarship of the
              Headmaster of the Academy of Music in Katowice. She successfully
              attended numerous competitions like: <strong> 2nd prize </strong>{" "}
              at the{" "}
              <em> II National Competition for violin viola in Warsaw</em>,{" "}
              <strong> 1st prize </strong> at the{" "}
              <em> II National Competition for String Instruments in Elbląg</em>
              , <strong> 1st prize </strong> and Special Prize at the{" "}
              <em>
                {" "}
                XIV Bohdan Warchal International Competition in Dolny Kubin
              </em>
              , <strong> Grand Prix </strong> in solo and chamber category at
              the{" "}
              <em>
                {" "}
                V International Music Competition Città di Francavilla Fontana
              </em>
              , <strong> 1st prize </strong> at the{" "}
              <em>
                {" "}
                VI Intenational Music Competition Città di Filadelfia Premio
                Speciale Paolo Serrao
              </em>
              , <strong> 1st prize </strong> at the{" "}
              <em>
                {" "}
                XXV International Competition for Young Musicians Citta di
                Barletta
              </em>
              ,<strong> 1st prize </strong> in solo and chamber music category
              at the{" "}
              <em>
                {" "}
                X International Music Competition Musica insiemie in Musile di
                Piave
              </em>
              , <strong> 1st prize </strong> at the solo category and
              <strong> 2nd prize </strong> at the chamber music category at the
              <em>
                {" "}
                15th Valsesia Musica Juniores International Competition
                Monterosa – Kawai Prize in Varallo Sesia
              </em>
              .
            </p>
          </div>
          <div className="left-col">
            <figure>
              <img
                src={bioFirstImage}
                alt="Malwina Tkaczyk"
                className="desktop-image"
              />
              <img
                src={bioFirstMobile}
                alt="Malwina Tkaczyk"
                className="mobile-image"
              />
            </figure>
          </div>
        </div>
        <div className="single-row">
          <div className="right-col">
            <LazyLoadImage
              alt="Malwina Tkaczyk"
              src={bioSecondImage}
              effect="opacity"
            />
          </div>
          <div className="left-col">
            <p>
              Malwina Tkaczyk is very active and ambitious musician. Chamber
              music has always been her biggest musical passion which determined
              her artistic route. When joining{" "}
              <strong> Airis String Quartet </strong> in 2019, she already had
              years of string quartet work experience with a number of
              ensembles. Besides intensive quartet activities she also
              collaborates with orchestras. Since 2017 she holds a position of a
              <strong> viola section leader </strong> at{" "}
              <strong> Archetti String Orchestra </strong> and often leads viola
              sections in other chamber orchestras like Silesian Chamber
              Orchestra, Bielsko Chamber Orchestra and many ensembles created
              for music festivals. In previous years she had also played as a
              <strong> guest player </strong> for symphony orchestras such as
              National Radio Symphony Orchestra in Katowice, Wrocław Philharmony
              Orchestra, Sinfonietta Cracovia, AUKSO Orchestra and worked under
              direction of conductors like Jerzy Maksymiuk, Szymon Bywalec,
              Marek Moś, Gabriel Chmura, Alexander Liebreich, Michał Klauza.
            </p>
            <p>
              Malwina Tkaczyk has always been very determined to share her
              experience, knowledge and enthusiasm for music and viola playing
              with a younger generation. For several years she have taught
              privately helping to develop instrumental skills to young
              violists. Since 2022 she holds her own{" "}
              <strong> viola class </strong> in{" "}
              <strong> Primary Music School in Dąbrowa Górnicza </strong> where
              she teaches basics of playing on this unique instrument to
              children from the age 6. In 2021 she has been hired as a{" "}
              <strong> viola and chamber music teacher </strong> also at the
              <strong> Krzysztof Penderecki Academy of Music in Cracow </strong>
              .
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <NavLink to="/biography" className="read-more">
            Back
          </NavLink>
        </div>
      </div>
    </StyledBio>
  );
}
