import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import bioImage from "../images/ola-bio-main.jpg";
import bioFirstImage from "../images/ola-bio-3.jpeg";
import bioFirstMobile from "../images/ola-bio-mobile.jpg";
import bioSecondImage from "../images/ola-bio-main.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin: 73px 0 120px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 30%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      margin-bottom: 20px;
    }
    span {
      font-family: "Cormorant Garamond", serif;
      color: #fff;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 60px;
      z-index: 3;
    }
  }
  .mobile-image {
    display: none;
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Libre Baskerville", serif;
  }
  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 150px;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 8rem;
    margin-top: -5rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 16px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
    }
    &.download {
      border: none;
    }
  }

  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
      &:nth-child(odd) {
        .right-col {
          width: 40%;
        }
        .left-col {
          width: 60%;
        }
      }
      &:nth-child(even) {
        .right-col {
          width: 60%;
        }
        .left-col {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        font-size: 40px;
      }
      span {
        margin-bottom: 50px;
      }
      img {
        height: 50vh;
      }
    }
    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .button-wrapper {
      padding: 3rem 0 5rem;
    }
    .single-row {
      margin-bottom: 60px;
      &:nth-child(odd) {
        flex-direction: column;
        .left-col {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }
        .right-col {
          width: 100%;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .left-col {
          width: 100%;
        }
        .right-col {
          width: 100%;
          margin-bottom: 3rem;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 160px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
        object-position: 50% 50%;
      }

      h1 {
        margin: auto 0 0 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 0 1rem;
      }
      span {
        color: #000;
        font-size: 1.5rem;
        margin: 20px 0 -130px 0;
      }
    }
    .mobile-image {
      display: block;
    }
    .desktop-image {
      display: none;
    }
  }
`;

export default function AleksandraEN() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledBio>
      <Helmet>
        <title>Aleksandra</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/aleksandra">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/aleksandra-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Aleksandra Czajor-Kowalska</h1>
        <span>1st violin</span>

        <img
          className="image-desktop"
          src={bioImage}
          alt="Aleksandra Czajor-Kowalska"
        />
      </div>
      <div className="bio-wrapper">
        <div className="single-row">
          <div className="right-col">
            <p>
              Her violin education started in{" "}
              <strong>Fryderyk Chopin Music School in Opole </strong> at the age
              of 6 where she studied for 12 years under guidance of prof. Danuta
              Sujata and prof. Janusz Gajownik. In 2007 she got accepted for
              bachelor’s and master's studies at the{" "}
              <strong>Academy of Music in Cracow </strong>
              in prof. Piotr Tarcholik violin class and graduated with
              distinctions in 2012. She continued her education at the{" "}
              <strong>ArtEZ Conservatorium </strong> Zwolle in class of prof.
              Jeroen Reuling and prof. Sarah Kapustin,{" "}
              <strong>
                Universität für Musik und darstellende Kunst in Vienna
              </strong>{" "}
              in class of prof. Johannes Meissl,{" "}
              <strong>Royal Conservatory Antwerp</strong> in class of prof.
              Tatiana Samouil and prof. Benjamin Braude and at the{" "}
              <strong>Karol Szymanowski Academy of Music in Katowice</strong> in
              class of prof. Piotr Szumieł. She also attended a number of
              chamber music, solo and orchestra masterclasses in Poland and
              abroad.
            </p>
            <p>
              In 2019 Aleksandra completed her 3 years of{" "}
              <strong>doctoral studies </strong> at the Academy of Music in
              Cracow and achieved a <strong>Doctor of Arts </strong> tittle with
              a doctoral thesis theme of{" "}
              <em>
                {" "}
                Karl Amadeus Hartmann – an inner emigration of the composer. The
                expression and musical language of works from the nazi era based
                on examples of the Concerto Funebre for violin and orchestra and
                string quartets.{" "}
              </em>{" "}
              Together with the string orchestra lead by prof. Piotr Tarcholik
              she recorded <strong>as soloist </strong> Hartmanns{" "}
              <em> Concerto Funebre </em> and she realised a CD album with his
              two string quartets recorded by{" "}
              <strong> Airis String Quartet</strong>. This cd album was a Polish
              phonographic premiere of Hartmanns music and achieved very
              enthusiastic rewievs .
            </p>
            <p>
              Aleksandra Czajor-Kowalska is a <strong>laureate </strong> of many
              artistic <strong>scholarships </strong> including{" "}
              <em> Sapere Auso, Young Poland </em> scholarship of the Ministry
              of Culture and National Heritage,{" "}
              <em>
                {" "}
                Agrafka Muzyczna, Złota Spinka, Windish Kammermusik Preis{" "}
              </em>{" "}
              to name a few. She also achieved several{" "}
              <strong>national artistic grants </strong> like{" "}
              <em> Artist in residence </em> or <em> Composition orders</em>. In
              her bio one can find many{" "}
              <strong>competition achievements </strong> as well. The most
              important are: <strong> 3rd prize </strong> at the{" "}
              <em> XXXI Bach National Competion in Zielona Góra</em>,{" "}
              <strong>Finalist tittle </strong> of the{" "}
              <em>
                {" "}
                VI International Contemporary Music Competition in Radziejowice
              </em>
              , 2nd prize at the{" "}
              <em>
                {" "}
                International Contemporary Chamber Music Competition in Cracow
              </em>
              ,<strong> 3rd prize</strong> at the{" "}
              <em> International Music Competition in Malta</em>,{" "}
              <strong>
                Special prize for the best performance of the XXth century
                repertoire{" "}
              </strong>{" "}
              at the Karol Szymanowski{" "}
              <em> International String Quartet Competition in Katowice</em> and{" "}
              <strong>
                Special prize for the best performance of the piece composed by
                Krzysztof Penderecki{" "}
              </strong>{" "}
              at the{" "}
              <em>
                {" "}
                II Ludvig van Beethoven International Chamber Music Competition
                in Lusławice
              </em>
              .
            </p>
          </div>
          <div className="left-col">
            <figure>
              <img
                src={bioSecondImage}
                alt="Aleksandra Czajor-Kowalska"
                className="desktop-image"
              />
              <img
                src={bioFirstMobile}
                alt="Aleksandra Czajor-Kowalska"
                className="mobile-image"
              />
            </figure>
          </div>
        </div>
        <div className="single-row">
          <div className="right-col">
            <LazyLoadImage
              alt="Aleksandra Czajor-Kowalska"
              src={bioFirstImage}
              effect="opacity"
            />
          </div>
          <div className="left-col">
            <p>
              Aleksandra Czajor-Kowalska is a very driven and versatile artist.
              Her passion and love for chamber music finds great fulfilment in
              <strong> Airis String Quartet </strong> which she{" "}
              <strong>founded </strong> and <strong>leads </strong> with
              commitment since 2008. As an open-minded and curious artist she
              takes up new challenges eagerly while entering chamber music
              projects with different musicians or co-operating with chamber and
              symphonic orchestras. She has a substantial experience in a role
              of the <strong>concert master leading </strong> chamber orchestra
              <strong> without conductor </strong> during years of active
              collaboration with <strong>Przemyśl Chamber Orchestra </strong>{" "}
              where she occasionally performed as a soloist too. In the past
              years she also played as a guest player in significant Polish
              orchestras e.g. National Radio Symphony Orchestra in Katowice,
              AUKSO chamber orchestra, Sinfonietta Cracovia, Beethoven Academy
              Orchestra where she worked under prominent conductors like Sir
              Neville Mariner, Gabriel Chmura, Jacek Kasprzyk, Jerzy Maksymiuk
              or Łukasz Borowicz, recorded plenty of CD albums and performed in
              Germany, France, Italy, Holland, Switzerland, England, Belgium,
              Austria, Czech Republic, Sweden, Denmark, China and USA.
            </p>
            <p>
              Aleksandra successfully splits her time between performing and
              teaching. Mentoring the upcoming generation of musicians is highly
              inspiring and important to her. Since 2017 Aleksandra is{" "}
              <strong>employed </strong>
              at the{" "}
              <strong>
                Karol Szymanowski Academy of Music in Katowice{" "}
              </strong>{" "}
              in a <strong>chamber music department</strong> and since 2013 she
              is a <strong>violin and chamber music teacher</strong> in the
              primary and secondary music school - currently at the{" "}
              <strong>Fryderyk Chopin Music School in Opole</strong>. Being an
              actively performing musician herself and working with young
              musicians of various education levels from the age of 6 till 26,
              gives her a wide perspective of their needs, challenges as well as
              interests, and possibilities. It makes her look at the educational
              process as a long-term, responsible task to sow sensibility and
              love for music and self- development. She is doing her best to be
              an <strong>promotor of chamber music practice </strong> for
              primary and secondary schools whilst encouraging teachers and
              students to find joy and inspiration in collective music making.
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <NavLink to="/biography" className="read-more">
            Back
          </NavLink>
        </div>
      </div>
    </StyledBio>
  );
}
