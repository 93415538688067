import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import bioImage from "../images/mateusz-bio-main.jpg";
import bioFirstImage from "../images/mateusz-bio-main.jpg";
import bioFirstMobile from "../images/mateusz-bio-mobile.jpg";
import bioSecondImage from "../images/mateusz-bio-2.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin: 73px 0 80px;

    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 30%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      margin-bottom: 20px;
    }
    span {
      font-family: "Cormorant Garamond", serif;
      color: #fff;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 60px;
      z-index: 3;
    }
  }
  .mobile-image {
    display: none;
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Libre Baskerville", serif;
  }
  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 150px;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    .right-col {
      text-align: center;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 70vh;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 8rem;
    margin-top: -5rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 16px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
    }
    &.download {
      border: none;
    }
  }

  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
      &:nth-child(odd) {
        .right-col {
          width: 40%;
        }
        .left-col {
          width: 60%;
        }
      }
      &:nth-child(even) {
        .right-col {
          width: 60%;
        }
        .left-col {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        font-size: 40px;
      }
      span {
        margin-bottom: 50px;
      }
      img {
        height: 50vh;
      }
    }
    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .button-wrapper {
      padding: 3rem 0 5rem;
    }
    .single-row {
      margin-bottom: 60px;
      &:nth-child(odd) {
        flex-direction: column;
        .left-col {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }
        .right-col {
          width: 100%;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .left-col {
          width: 100%;
        }
        .right-col {
          width: 100%;
          margin-bottom: 3rem;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 160px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
        object-position: 50% 50%;
      }

      h1 {
        margin: auto 0 0 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 0 1rem;
      }
      span {
        color: #000;
        font-size: 1.5rem;
        margin: 20px 0 -130px 0;
      }
    }
    .mobile-image {
      display: block;
    }
    .desktop-image {
      display: none;
    }
  }
`;

export default function MateuszEN() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledBio>
      <Helmet>
        <title>Mateusz</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/mateusz">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/mateusz-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Mateusz Mańka</h1>
        <span>cello</span>
        <img className="image-desktop" src={bioImage} alt="Mateusz Mańka" />
      </div>
      <div className="bio-wrapper">
        <div className="single-row">
          <div className="right-col">
            <p>
              Mateusz started his cello education at the age of 7 at the
              <strong> Fryderyk Chopin Music School in Bytom </strong> and
              studied there for 12 years in a cello class of prof. Bernard
              Szczędzina. In 2010 he got accepted for the bachelor’s and
              master’s studies at the{" "}
              <strong> Karol Szymanowski Academy of Music in Katowice </strong>{" "}
              in a cello class of prof. Piotr Janosik and graduated in 2015. He
              developed his solo instrumental and chamber music skills on
              numerous workshops and masterclasses with artists like: Ivan
              Monighetti, Cecylia Barczyk, Kazimierz Michalik, Paweł Głombik,
              Stanisław Firlej, Andrzej Orkisz, Alexander Gebert,
              Claus-Christian Schuster, Tim Frederiksen and Roland Baldini.
            </p>
            <p>
              Mateusz is a <strong> laureate of scholarships </strong> e.g.
              scholarship of the President of his home town Piekary Śląskie or
              <em> Young Poland </em> scholarship from Ministry of Culture and
              National Heritage. He also successfully attended a number of
              competitions:
              <strong> Distinction </strong> at the{" "}
              <em>
                {" "}
                V National Chamber Music Competition for ensembles with guitar
                in Tychy{" "}
              </em>{" "}
              (guitar-cello duo),
              <strong> 1st prize </strong> at the{" "}
              <em> V National Chamber Music Competition in Świdnica </em>{" "}
              (guitar-cello duo), <strong> 2nd prize </strong> and Special at
              the <em> National Chamber Music Competition in Cracow</em>,{" "}
              <strong> 2nd prize </strong> at the{" "}
              <em> XII International Guitar Music Festival in Trzęsacz </em>{" "}
              (guitar-cello duo), <strong> Distinction </strong> at the{" "}
              <em>
                {" "}
                XIV Juliusz Zarębski International Music Competition in Łomianki
              </em>
              .
            </p>
          </div>
          <div className="left-col">
            <figure>
              <img
                src={bioFirstImage}
                alt="Mateusz Mańka"
                className="desktop-image"
              />
              <img
                src={bioFirstMobile}
                alt="Mateusz Mańka"
                className="mobile-image"
              />
            </figure>
          </div>
        </div>
        <div className="single-row">
          <div className="right-col">
            <LazyLoadImage
              alt="Mateusz Mańka"
              src={bioSecondImage}
              effect="opacity"
            />
          </div>
          <div className="left-col">
            <p>
              Mateusz Mańka is an extremely active and open-minded musician. He
              shares his love and passion to both{" "}
              <strong> chamber music and orchestra</strong>. Since 2015 he holds
              a position of the <strong> cello section leader </strong> in
              <strong> Archetti Chamber Orchestra </strong> and since 2018 works
              as a cellist at the{" "}
              <strong> Silesian Opera Ensemble in Bytom</strong>. When joining
              <strong> Airis String Quartet </strong>in 2019, Mateusz already
              had years of string quartet work experience with musicians from
              AUKSO orchestra. He is also a member of the cello quartet called
              <strong> Cello Maniax </strong> where he performs and records
              with. Mateusz is also often invited as a guest player and cello
              section leader for other orchestras and ensembles. His
              professional activities are complemented with his{" "}
              <strong> pedagogical work </strong>
              as a successful cello teacher at the primary and secondary{" "}
              <strong> Music School in Bytom</strong>.
            </p>
            <p>
              In previous years he co-operated as a{" "}
              <strong> guest musician </strong> with orchestras such as National
              Radio Symphony Orchestra in Katowice, AUKSO Orchestra, New Music
              Orchestra, Gorzów Philharmonic Orchestra, Kalisz Philharmonic
              Orchestra, Radom Chamber Orchestra, Sinfonietta Cracovia. He
              performed under direction of Larry Livingston, Paul McCreesh,
              George Tchitchinadze, Krzysztof Penderecki, Gabriel Chmura, Antoni
              Wit,Mirosław Jacek Błaszczyk, Marek Moś, Michał Klauza, Szymon
              Bywalec in Czech Republic, Bulgaria, Italy, Switzerland, Germany,
              France, Holland, USA, Canada.{" "}
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <NavLink to="/biography" className="read-more">
            Back
          </NavLink>
        </div>
      </div>
    </StyledBio>
  );
}
