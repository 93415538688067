import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import bioImage from "../images/malwina-bio-2.jpg";
import bioFirstImage from "../images/malwina-bio-2.jpg";
import bioFirstMobile from "../images/malwina-bio-mobile.jpg";
import bioSecondImage from "../images/malwina-bio.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin: 73px 0 80px;

    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 10%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      margin-bottom: 20px;
    }
    span {
      font-family: "Cormorant Garamond", serif;
      color: #fff;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 60px;
      z-index: 3;
    }
  }
  .mobile-image {
    display: none;
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Libre Baskerville", serif;
  }
  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 150px;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 8rem;
    margin-top: -5rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 16px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
    }
    &.download {
      border: none;
    }
  }

  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
      &:nth-child(odd) {
        .right-col {
          width: 40%;
        }
        .left-col {
          width: 60%;
        }
      }
      &:nth-child(even) {
        .right-col {
          width: 60%;
        }
        .left-col {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        font-size: 40px;
      }
      span {
        margin-bottom: 50px;
      }
      img {
        height: 50vh;
      }
    }
    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .button-wrapper {
      padding: 3rem 0 5rem;
    }
    .single-row {
      margin-bottom: 60px;
      &:nth-child(odd) {
        flex-direction: column;
        .left-col {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }
        .right-col {
          width: 100%;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .left-col {
          width: 100%;
        }
        .right-col {
          width: 100%;
          margin-bottom: 3rem;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 160px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
        object-position: 50% 50%;
      }

      h1 {
        margin: auto 0 0 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 0 1rem;
      }
      span {
        color: #000;
        font-size: 1.5rem;
        margin: 20px 0 -130px 0;
      }
    }
    .mobile-image {
      display: block;
    }
    .desktop-image {
      display: none;
    }
  }
`;

export default function Malwina() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledBio>
      <Helmet>
        <title>Malwina</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/malwina">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/malwina-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/filmy">Filmy</NavLink>
              <NavLink to="/galeria">Zdjęcia</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Malwina Tkaczyk</h1>
        <span>Altówka</span>
        <img className="image-desktop" src={bioImage} alt="Malwina Tkaczyk" />
      </div>
      <div className="bio-wrapper">
        <div className="single-row">
          <div className="right-col">
            <p>
              Naukę gry na altówce rozpoczęła w wieku 7 lat w{" "}
              <strong>
                Ogólnokształcącej Szkole Muzycznej I i II st.  im. Fryderyka
                Chopina w Bytomiu
              </strong>{" "}
              pod kierunkiem Marty Werner. W wieku 16 lat rozpoczęła równolegle
              naukę gry na altówce w klasie Ireny Kalinowskiej, gdzie odnalazła
              swoje prawdziwe muzyczne powołanie. W roku 2014 ukończyła z
              wyróżnieniem studia licencjackie w klasie prof. Zygmunta
              Jochemczyka i dr hab. Elżbiety Mrozek -Loski na{" "}
              <strong>
                {" "}
                Akademii Muzycznej im. Karola Szymanowskiego w Katowicach
              </strong>
              . W czerwcu 2016 roku otrzymała tytuł magistra w klasie altówki
              prof. Piotra Szumieła na tej samej uczelni. W kolejnych latach
              zrealizowała tu również <strong>studia podyplomowe</strong> w
              klasie kameralistyki prof. dr hab. Arkadiusza Kubicy oraz st.
              wykł. Marka Mosia. Swoje umiejętności doskonaliła także na
              licznych kursach mistrzowskich w kraju i za granicą pod kierunkiem
              m.in. Alexandra Geberta, Clausa-Christiana Schustera, Amirama
              Ganza, Tima Frederiksena, członków Royal String Quartet. 
            </p>
            <p>
              Malwina Tkaczyk  jest <strong> laureatką </strong> wielu{" "}
              <strong>stypendiów</strong> – m.in. stypendium Prezydenta Miasta
              Świętochłowice, stypendium Ministra Nauki i Szkolnictwa
              Wyższego,  stypendium Rektora Akademii Muzycznej im. Karola
              Szymanowskiego w Katowicach a w 2014 roku otrzymała Nagrodę
              Ministra Kultury za osiągnięcia artystyczne. Jest także laureatką
              programu Ministra Kultury i Dziedzictwa Narodowego{" "}
              <em> Zamówienia kompozytorskie </em> oraz zdobywczynią wielu
              nagród na ogólnopolskich i międzynarodowych konkursach solowych i
              kameralnych:  <strong>II miejsce</strong> na{" "}
              <em>
                {" "}
                Ogólnopolskich Przesłuchaniach Klas Skrzypiec i Altówki w
                Warszawie
              </em>
              , <strong>I miejsce</strong> na  
              <em>
                {" "}
                II Ogólnopolskim Konkursie Instrumentów Smyczkowych w Elblągu
              </em>
              , <strong>II miejsce i Nagroda Specjalna</strong>{" "}
              <em>
                {" "}
                Musica Arvenzis na  XIV Międzynarodowym  Konkursie Muzycznym im.
                Bohdana Warchala w Dolnym Kubinie
              </em>
              , <strong>I miejsce </strong> na{" "}
              <em>
                {" "}
                XVI Międzynarodowym Konkursie Muzycznego im. Bohdana Warchala w
                Dolnym Kubinie
              </em>
              , <strong>Grand Prix</strong> w kategorii solowej i kameralnej
              na  
              <em>
                {" "}
                V Międzynarodowym Konkursie Muzycznym Città di Francavilla
                Fontana
              </em>
              , <strong>I miejsce </strong>na{" "}
              <em>
                {" "}
                VI Międzynarodowym Konkursie Muzycznym Città di Filadelfia
                Premio Speciale Paolo Serrao
              </em>
              , <strong>I miejsce</strong> na  
              <em>
                {" "}
                XXV Międzynarodowym Konkursie Młodych Muzyków Citta di Barletta
              </em>
              , I miejsce w kategorii solowej i <strong>Grand Prix</strong> w
              kategorii kameralnej na  
              <em>
                {" "}
                X Międzynarodowym Konkursie Muzycznym Musica Insieme w Musile di
                Piave
              </em>
              , <strong>I miejsce</strong> w kategorii solowej i{" "}
              <strong>II miejsce</strong> w kategorii kameralnej na{" "}
              <em>
                {" "}
                15th Valsesia Musica Juniores International Competition
                Monterosa – Kawai Prize
              </em>{" "}
              w miejscowości Varallo Sesia. 
            </p>
          </div>
          <div className="left-col">
            <figure>
              <img
                src={bioFirstImage}
                alt="Malwina Tkaczyk"
                className="desktop-image"
              />
              <img
                src={bioFirstMobile}
                alt="Malwina Tkaczyk"
                className="mobile-image"
              />
            </figure>
          </div>
        </div>
        <div className="single-row">
          <div className="right-col">
            <LazyLoadImage
              alt="Malwina Tkaczyk"
              src={bioSecondImage}
              effect="opacity"
            />
          </div>
          <div className="left-col">
            <p>
              Malwina Tkaczyk jest bardzo aktywnym i ambitnym muzykiem.
              Kameralistyka od zawsze była jej największą pasją i marzeniem
              determinującym jej rozwój i artystyczne decyzje. Gdy dołączyła do{" "}
              <strong>Airis String Quartet</strong> w 2019 roku miała już za
              sobą spore doświadczenie w grze kameralnej w różnych zespołach.
              Poza intensywną działalnością w kwartecie Malwina aktywnie
              współpracuje także z <strong>orkiestrami</strong>. Od 2017 roku
              pełni funkcję <strong>lidera sekcji altówek</strong> w{" "}
              <strong>Orkiestrze Kameralnej Miasta Jaworzna Archetti</strong> a
              także często gościnnie koncertuje jako lider np. w Śląskiej
              Orkiestrze Kameralnej, Bielskiej Orkiestrze Kameralnej czy innych
              zespołach. W poprzednich latach koncertowała także jako gościnny
              muzyk w takich orkiestrach jak: NOSPR, Filharmonia Wrocławska,
              Sinfonietta Cracovia, AUKSO i współpracowała z dyrygentami: Jerzy
              Maksymiuk, Szymon Bywalec, Marek Moś, Gabriel Chmura, Alexander
              Liebreich, Michał Klauza.
            </p>
            <p>
              Malwina od wielu lat przykłada wielką wagę do popularyzacji gry na
              altówce i dzieli się swoim entuzjazmem, pasją i doświadczeniem z
              młodszym pokoleniem. Przez wiele lat uczyła prywatnie a od 2022
              roku prowadzi własną klasę altówki w{" "}
              <strong>
                Ogólnokształcącej Szkole Muzycznej I stopnia im. Michała Spisaka
                w Dąbrowie Górniczej
              </strong>
              , ucząc podstaw gry na altówce dzieci od 6 roku życia. Od 2021
              roku jest także zatrudniona na{" "}
              <strong>
                Akademii Muzycznej im. Krzysztofa Pendereckiego w Krakowie
              </strong>{" "}
              gdzie uczy altówki i zespołów kameralnych.
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <NavLink to="/biografia" className="read-more">
            Powrót
          </NavLink>
        </div>
      </div>
    </StyledBio>
  );
}
