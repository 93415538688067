import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import sanityClient from "../client.js";
import { Link, NavLink } from "react-router-dom";
import discographyImage from "../images/discography.jpg";
import arrowRight from "../images/arrow-right-small.svg";
import styled from "styled-components";
import imageUrlBuilder from "@sanity/image-url";
import homeImage from "../images/home-image.jpg";
import pageLogo from "../images/airis-logo-small.png";
import BlockContent from "@sanity/block-content-to-react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const StyledDiscography = styled.section`
  background: #fff;
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 73px 0 160px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 35%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      margin-bottom: 160px;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
    }
  }
  .posts-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  figure {
    margin: 0;
  }

  .inside-post {
    display: flex;
    padding-bottom: 80px;
    margin-bottom: 80px;
    position: relative;
    .content-col,
    .image-col {
      padding: 0 32px;
      width: 50%;
    }
  }
  .image-col {
    position: relative;
  }
  a {
    text-decoration: none;
  }

  .content-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    h3 {
      font-weight: 700;
      color: #000;
      font-size: 1.375rem;
      line-height: 1.5em;
      margin-bottom: 15px;
      transition: 0.25s ease-out;
    }
    .social-nav {
      flex-direction: row;
      li {
        width: 30px;
        height: 30px;
        margin: 0 24px 20px 0;
        a {
          cursor: pointer;
          &:before {
            filter: invert(0%) sepia(50%) saturate(7500%) hue-rotate(202deg)
              brightness(0%) contrast(00%);
          }
        }
      }
    }
  }
  .short-text {
    font-size: 1rem;
    line-height: 1.5em;
    color: #bf825a;
    margin-bottom: 15px;
    text-align: justify;
    font-weight: 600;
    + a {
      margin-left: auto;
    }
  }
  .block-content {
    > * {
      color: #000;
      font-size: 0.9375rem;
      line-height: 1.6em;
    }
    h1 {
      color: #000;
      font-size: 2.75rem;
      line-height: 1.3em;
      font-weight: 600;
      margin: 0 0 50px;
      text-align: left;
    }
    h2 {
      color: #000;
      font-size: 2rem;
      font-weight: normal;
      line-height: 1.3em;
      margin: 50px 0 50px;
      padding: 40px 0 0;
      font-weight: 600;
      text-align: left;
    }
    h3 {
      color: #000;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.3em;
      margin: 0 0 50px;
      padding: 40px 0 0;
      font-weight: 600;
      text-align: left;
    }
    p,
    li,
    blockquote {
      color: #000;
      font-size: 0.9375rem;
      line-height: 1.5em;
    }
    ul {
      padding-left: 16px;
      list-style: numbers;
      li {
        margin-bottom: 4px;
      }
    }
    p,
    blockquote {
      margin: 0 0 20px;
    }
    a {
      color: #bf825a;
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.25s ease-out;
      word-break: break-all;
      white-space: normal;
      &:hover {
        color: #89502a;
      }
    }
    strong {
      font-weight: 700;
    }
    em,
    blockquote {
      font-style: italic;
    }
  }
  .image-col {
    text-align: center;
    .lazy-load-image-background {
      height: 100%;
      text-align: center;
    }
    figure {
      text-align: center;
    }
    img {
      height: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 100px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
      }
      img {
        height: 50vh;
        object-position: 50% 75%;
      }
    }
    .single-post {
      .inside-post {
        flex-direction: column;
      }
    }
    .inside-post {
      flex-direction: column;
      padding-bottom: 60px;
      margin-bottom: 60px;
      .image-col {
        order: -1;
        margin-bottom: 30px;
      }
      .content-col,
      .image-col {
        padding: 0 20px;
        width: 100%;
      }

      .content-col {
        h3 {
          font-size: 1.25rem;
          margin-bottom: 20px;
        }
        .short-text {
          font-size: 0.875rem;
          margin-bottom: 20px;
        }
      }
      .block-content {
        h1 {
          font-size: 1.875rem;
          margin: 0 0 50px;
        }
        h2 {
          font-size: 1.5rem;
          margin: 0 0 50px;
          padding: 40px 0 0;
        }
        h3 {
          font-size: 1.125rem;
          margin: 0 0 50px;
          padding: 40px 0 0;
        }
        p,
        li,
        blockquote {
          font-size: 16px;
        }
        ul {
          li {
            margin-bottom: 12px;
          }
        }
        p,
        blockquote {
          margin: 0 0 20px;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 140px;
      height: calc(40vh - 68px);
      img {
        height: 100%;
      }
      .image-mobile {
        display: block;
      }

      h1 {
        margin: auto 0 -80px 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
      }
      .block-content {
        h1 {
          font-size: 1.5rem;
          margin: 0 0 40px;
        }
        h2 {
          font-size: 1.35rem;
          margin: 0 0 40px;
        }
        h3 {
          margin: 0 0 40px;
        }
        p,
        blockquote {
          margin: 0 0 16px;
        }
        p,
        li,
        blockquote {
          font-size: 0.875rem;
        }
      }
    }
  }
`;

export default function Discography() {
  const [discographyData, setDiscography] = useState(null);

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "discography"] | order(order asc) {
      titleEN,
      socials,
      spotify,
      youtube,
      description,
      bodyEN, 
      mainImage {
        asset->{
          _id,
          url
        },
        alt
      }
    }`
      )
      .then((data) => setDiscography(data))
      .catch(console.error);
  }, []);

  return (
    <StyledDiscography>
      <Helmet>
        <title>Discography</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/dyskografia">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/discography">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Discography</h1>
        <img
          className="image-desktop"
          src={discographyImage}
          alt="Dyskografia Airis String Quartet"
        />
      </div>
      <div className="posts-wrapper">
        {discographyData &&
          discographyData.map((discography, index) => (
            <article key={index} className="single-post">
              <div className="inside-post">
                {discography?.mainImage && (
                   <div className="image-col">
                     <LazyLoadImage
                        alt={discography.mainImage.alt}
                        src={discography.mainImage.asset.url}
                        effect="opacity"
                     />
                   </div>
                )}
                <div className="content-col">
                  <ul className="social-nav">
                    <li
                      className={
                        discography.spotify
                          ? "visible link-spotify "
                          : "not-visible link-spotify"
                      }
                    >
                      <a
                        target="_blank"
                        href={discography.spotify ? discography.spotify : "#"}
                      ></a>
                    </li>
                    <li
                      className={
                        discography.youtube
                          ? "visible link-youtube"
                          : "not-visible link-youtube"
                      }
                    >
                      <a target="_blank" href={discography.youtube}></a>
                    </li>
                    <li
                      className={
                        discography.custom
                          ? "visible link-custom"
                          : "not-visible link-custom"
                      }
                    >
                      <a target="_blank" href={discography.custom}></a>
                    </li>
                  </ul>
                  <h3>{discography.titleEN}</h3>
                  <div className="short-text">
                    <BlockContent
                      blocks={discography.description}
                      projectId="gysyst6l"
                      dataset="production"
                    />
                  </div>
                  <div className="block-content">
                    <BlockContent
                      blocks={discography.bodyEN}
                      projectId="gysyst6l"
                      dataset="production"
                    />
                  </div>
                </div>
              </div>
            </article>
          ))}
      </div>
    </StyledDiscography>
  );
}
