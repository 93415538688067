import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import arrowRight from "../images/arrow-right-small.svg";
import arrowBottom from "../images/../images/arrow-bottom.svg";
import bioImage from "../images/bio-image.jpg";
import bioFirstImage from "../images/bio-first-image.jpg";
import bioSecondImage from "../images/bio-second-image.jpg";
import bioThirdImage from "../images/bio-third-image.jpg";
import bioFourthImage from "../images/bio-fourth-image.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 73px 0 70px;

    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 30%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      margin-bottom: 160px;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
    }
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
    font-family: "Libre Baskerville", serif;
  }

  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .team-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0 15px 80px 15px;
    li {
      margin-bottom: 10px;
      a {
        text-decoration: none;
        font-size: 1rem;
        line-height: 1.3em;
        color: #000;
        transition: 0.25s ease-out;
        font-weight: 500;
        position: relative;
        padding-left: 1.5rem;
        &:before {
          content: "";
          position: absolute;
          width: 0.875rem;
          height: 0.875rem;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          left: 0;
          background: url(${arrowBottom}) no-repeat center center;
          background-size: contain;
          transition: 0.25s ease-out;
          z-index: 2;
        }
        &:hover {
          color: #bf825a;
        }
        span {
          font-weight: 700;
        }
      }
    }
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem 0 3rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 36px 8px 8px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    text-decoration: none;
    cursor: pointer;
    &.active {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      width: 0.875rem;
      height: 0.875rem;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      right: 0.75rem;
      background: url(${arrowRight}) no-repeat center center;
      background-size: contain;
      transition: 0.25s ease-out;
    }
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
      &:after {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(34deg)
          brightness(103%) contrast(101%);
      }
    }
    &.download {
      border: none;
      padding: 8px;
      &:after {
        display: none;
      }
    }
  }
  .expanded-wrapper {
    display: none;
    position: relative;
    &.active {
      display: block;
    }
    .scroll-target {
      position: absolute;
      left: 0;
      top: -150px;
    }
    .single-row {
      margin-bottom: 150px;
    }
  }
  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
    }

    .expanded-wrapper {
      .single-row {
        &:nth-child(odd) {
          .right-col {
            width: 40%;
          }
          .left-col {
            width: 60%;
          }
        }
        &:nth-child(even) {
          .right-col {
            width: 60%;
          }
          .left-col {
            width: 40%;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
      }
      img {
        height: 50vh;
      }
    }
    .button-wrapper {
      padding: 1rem 0 2rem;
    }

    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .team-links {
      li {
        text-align: center;
        a {
          text-align: center;
          font-size: 0.9375rem;
        }
      }
      + .single-row {
        flex-direction: column;
        .left-col,
        .right-col {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
    .expanded-wrapper {
      .single-row {
        margin-bottom: 60px;
        &:nth-child(odd) {
          flex-direction: column-reverse;
          .right-col {
            width: 100%;
            margin-bottom: 3rem;
            padding: 0;
          }
          .left-col {
            width: 100%;
          }
        }
        &:nth-child(even) {
          flex-direction: column;
          .right-col {
            width: 100%;
          }
          .left-col {
            width: 100%;
            margin-bottom: 3rem;
            padding: 0;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 120px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
      }

      h1 {
        margin: auto 0 -80px 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 350px) {
    .team-links {
      li {
        a {
          font-size: 0.875rem;
        }
      }
    }
  }
`;

export default function BioEn() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  const [isExpanded, setExpanded] = useState("false");
  const ToggleExpanded = () => {
    setExpanded(!isExpanded);
    setTimeout(() => {
      document.querySelector(".scroll-target").scrollIntoView();
    }, 150);
  };

  return (
    <StyledBio>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Biography</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/biografia">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/biography">EN</NavLink>
          </li>
        </ul>

        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Biography</h1>
        <img
          className="image-desktop"
          src={bioImage}
          alt="Zdjęcie Airis Quartet"
        />
      </div>
      <div className="bio-wrapper">
        <h2>Airis String Quartet</h2>
        <ul className="team-links">
          <li>
            <NavLink to="/aleksandra-en">
              Aleksandra Czajor-Kowalska <span> 1st violin</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/grazyna-en">
              Grażyna Zubik<span> 2nd violin</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/malwina-en">
              Malwina Tkaczyk<span> viola</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/mateusz-en">
              Mateusz Mańka<span> cello</span>
            </NavLink>
          </li>
        </ul>
        <div className="single-row">
          <div className="left-col">
            <p>
              <strong>
                <em>Airis String Quartet </em>
              </strong>{" "}
              was founded in 2008 in Cracow to pour a very untamed student
              passion into the <strong>modern music </strong>. From the very
              beginning the fascinating field of their musical exploration (even
              though not a very common for the young string quartet) was music
              of the Polish contemporary composers with Krzysztof Penderecki at
              the forefront and the 20th and 21st century’s world music
              literature. Quite an audacious jump into challenging contemporary
              repertoire brought the quartet first success at the international
              competition and audience’s enthusiasm. Student dream about
              professional string quartet career developed into a fully mature
              choice of artistic life route.
            </p>
          </div>
          <div className="right-col">
            <p>
              The ensemble have been actively performing for{" "}
              <strong>over 14 years </strong> now. Considered to be one of the
              most interesting Polish string quartets of the young generation,{" "}
              <em>Airis String Quartet </em> is a laureate of many international
              competitions, scholarships and national artistic grants. The
              ensemble completed its education in prominent music centres in
              Europe, recorded numerous CD albums, premiered new compositions,
              collaborated with outstanding musicians. The{" "}
              <em>Airis String Quartet </em>
              is distinguished by its extraordinary expression on stage,
              sincerity of emotions and special sense in performing modern
              music. The ensemble believes that <em>modern music </em> is
              naturally a <em>mirror of the time of its creation </em>. Such
              understanding is key to their successful interpretation of
              repertoire and captivating performances.
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            onClick={ToggleExpanded}
            className={isExpanded ? "read-more" : "read-more active"}
          >
            Read more
          </button>
        </div>

        <div
          className={
            isExpanded ? "expanded-wrapper" : "expanded-wrapper active"
          }
        >
          <div className="scroll-target"></div>
          <div className="single-row">
            <div className="left-col">
              <figure>
                <img src={bioFirstImage} alt="Zdjęcie Airis Quartet" />
              </figure>
            </div>
            <div className="right-col">
              <p>
                <strong>
                  <em>Airis String Quartet</em>{" "}
                </strong>{" "}
                founded in <strong>2008 </strong> in Krzysztof Penderecki
                Academy of Music in Cracow took his first ensemble steps under
                direction of Dafô Quartet in chamber music and contemporary
                music department. Less than a half year after coming to an
                existence Airis achieved the first significant success -{" "}
                <strong>II Prize </strong> at the International Contemporary
                Chamber Music Competition in Cracow. This achievement strongly
                influenced quartets further growth and prompted to search for
                its <strong>own artistic niche. </strong>
              </p>
              <p>
                Following years brought further competition{" "}
                <strong>prizes and distinctions </strong> such as:{" "}
                <em>Charles Hennen Competition </em> in Holland (EMCY Prize for
                Excellent Performance, 2012),{" "}
                <em>Malta International Music Competition </em> (
                <strong>3rd prize </strong> , 2015),{" "}
                <em>
                  Karol Szymanowski International String Quartet Competition in
                  Katowice{" "}
                </em>{" "}
                (
                <strong>
                  Special Prize for the best XXth Century composition
                  performance{" "}
                </strong>
                , 2017) and{" "}
                <em>
                  Międzynarodowy Ludvig van Beethoven International Chamber
                  Music Competition in Lusławice{" "}
                </em>{" "}
                (
                <strong>
                  Special Prize for the best performance of the Krzysztof
                  Penderecki’s composition
                </strong>
                , 2017).
              </p>
            </div>
          </div>
          <div className="single-row">
            <div className="left-col">
              <p>
                <em>Airis String Quartet </em> had mastered their skills also at
                the <strong>ArtEZ Conservatorium Zwolle </strong> in Holland
                under direction of prof. Jeroen Reuling and prof. Sarah Kapustin
                (Rubens Quartet),{" "}
                <strong>
                  Universität für Musik und darstellende Kunst in Vienna{" "}
                </strong>{" "}
                under direction of prof. Johannes Meissl (Artis Quartet),{" "}
                <strong>Karol Szymanowski Academy of Music in Katowice </strong>{" "}
                under direction of prof. Piotr Szumieł (Apollon Musagete
                Quartet). As a member of the{" "}
                <strong>European Chamber Music Academy (ECMA) </strong>, it took
                part in a number of masterclasses and worked with such
                outstanding ensembles as{" "}
                <em>
                  Ysaÿe Quartet, Alban Berg Quartet, Melos Quartet, Quatuor
                  Danel, Szymanowski Quartet, Silesian String Quartet, Royal
                  String Quartet{" "}
                </em>{" "}
                and <em>Apollon Musagète Quartett</em>.
              </p>
              <p>
                Very important role in the quartets education and development
                played the unique masterclasses of contemporary music
                performance <strong>Diotima Academy </strong> in France for
                string quartets and young composers directed by fantastic French
                <em> Diotima Quotour</em>. With other two string quartets and
                three composers they got to stay in a picturesque and peaceful
                setting of Abbey de Noirlack. Such a cameral setting enabled
                particularly fruitful{" "}
                <strong>
                  {" "}
                  exchange of perspectives and different approaches to new music
                  between performers and creators
                </strong>
                . Ensembles got to know techniques for both performance and
                notation, developed instrumental skills and precision as well as
                score interpretation whilst searching for radical sound
                possibilities of the instrument.
              </p>
              <p>
                At present quartet members share their experience and passion
                with younger generation - they <strong>teach </strong> at the
                <strong>
                  {" "}
                  Karol Szymanowski Academy of Music in Katowice, Krzysztof
                  Penderecki Academy of Music{" "}
                </strong>{" "}
                and in primary and secondary <strong>music schools </strong> in
                Opole, Bytom, Dąbrowa Górnicza and Domosławice.{" "}
              </p>
            </div>
            <div className="right-col">
              <LazyLoadImage
                alt="Zdjęcie Airis Quartet"
                src={bioSecondImage}
                effect="opacity"
              />
            </div>
          </div>
          <div className="single-row">
            <div className="left-col">
              <LazyLoadImage
                alt="Zdjęcie Airis Quartet"
                src={bioThirdImage}
                effect="opacity"
              />
            </div>
            <div className="right-col">
              <p>
                First years of <em>Airis String Quartets </em> musical
                activities and development was mostly devoted to the{" "}
                <strong>XXth </strong> and <strong>XXIst century’s </strong>{" "}
                music from Shostakovich, Bartók, to atonality, sonorism,
                aleatorism and newest compositions. The ensemble explored
                intensively the{" "}
                <strong>legacy of Polish local contemporary composers </strong>
                (what resulted in recording the first CD Album released by
                Cracow Academy Edition containing chamber works of significant
                Cracow composers Zbigniew Bujarski and Marek Stachowski).
                Willing to develop as broadly as possible naturally pushed{" "}
                <em>Airis </em>
                to explore masterpieces of <strong> all musical periods</strong>
                , searching for artists and professors who are experts of
                radical musical styles. Quartet undertook intensive education
                taking to different international masterclasses, studies in
                Holland, Belgium, Austria, France, Germany, England, private
                lessons with respected artists. The ensemble learned about
                recesses of rhetoric figures in baroque and classical music
                language, performance aesthetic of French music, interpretation
                and contexts of romantic and late romantic compositions and
                finally contemporary music techniques.{" "}
              </p>
              <p>
                Quartet’s musical programme is rich in variety. Composing their
                concerts <strong>repertoire </strong> they often juxtapose
                different musical styles and periods selected on a basis of a
                <strong> dialogue, connections, contexts and contrasts</strong>.
                In contemporary music the ensemble finds freedom, wildness and
                relevance to current time. It is a blank slate and field for
                challenges, creative experimentations, an escape route from
                traditional canons of performing. Classical music in turn, is
                their sphere of respite, genius balance and space to both ground
                and develop skills.{" "}
              </p>
            </div>
          </div>
          <div className="single-row">
            <div className="left-col">
              <p>
                <em>Airis String Quartet </em> is constantly searching for{" "}
                <strong> new inspirations</strong>, space for creative freedom.
                Eagerly reaches for unknown and{" "}
                <strong>unobvious repertoire </strong> (e.g. the ensemble
                recorded CD Album Hartmann devoted to chamber music works by
                Karl Amadeus Hartmann, forgotten antinazi German composer of the
                II World War period) and takes to <strong>premiere </strong>{" "}
                many new music pieces. The ensemble worked with many{" "}
                <strong>composers </strong>
                such as: Miłosz Bembinow, Rafał Augustyn, Ib Nørholm, Paweł
                Łukaszewski, Michał Dobrzyński, Łukasz Pieprzyk, Anders Koppel,
                Clarck Nichols, Christian Mason. <em>Airis</em> had the honour
                and pleasure to meet and work twice with a legendary{" "}
                <strong>Maestro Krzysztof Penderecki </strong> while working on
                his 2nd and 3rd String Quartet. Those pieces have a special
                place in quartet’s repertoire and attracted it numerous
                competition prizes.{" "}
              </p>
              <p>
                <strong>Collaboration </strong> is an ultimate source of
                inspiration for <em>Airis String Quartet </em>. Within their bio
                one can find projects with accordion duo{" "}
                <strong> Accosphere</strong>, percussion trio{" "}
                <strong>Amadrums, vocal ensembles </strong> and{" "}
                <strong>choirs </strong> (those projects resulted in CD Albums
                like{" "}
                <em>
                  Accordion Anatomy, Airis String Quartet/Amadrums Trio/Łukasz
                  Pieprzyk{" "}
                </em>{" "}
                for instrumental septet and electronics, <em> Ave Maria </em>{" "}
                for girls choir <em>Puellae Orantes </em> and string quartet).
                Ensemble performs also in <strong>quintets </strong> with
                clarinet , saxophone, piano and voice. In 2021 took place a
                recording session of new compositions by Clark Nichols and
                Anders Koppel for mandolin and string quartet which{" "}
                <em>Airis </em> got invited to by Israeli mandolin virtuoso Alon
                Sariel (CD release is planned for Autumn 2022).{" "}
              </p>
              <p>
                <em>Airis String Quartet </em> explores{" "}
                <strong> different music genres</strong>. They recorded{" "}
                <strong>soundtrack for computer games </strong> (Kholat, Father
                and Son), <strong>soundtrack </strong> for Polish-French{" "}
                <strong>documentary movie </strong>{" "}
                <em>Dans les pas de Marie Curie </em>
                directed by Krzysztof Rogulski with music composed by Krzysztof
                A. Janczak. In 2015 took part in recording session in Miami
                (Florida) for CD Album <em>Hello </em> of the{" "}
                <em>Daddy’s Cash Band </em>
                (pop/RnB), and since 2018 <em>Airis </em> cooperates with the
                ensemble <strong>Nocą Umówieni </strong> together with whom
                recorded two albums <em>Nocą umówieni </em> (2018) and{" "}
                <em>Ginczanka </em>
                (2021) representing music style called{" "}
                <strong>lyric fusion </strong> influenced by jazz, poetry,
                classical music and theatre.{" "}
              </p>
            </div>
            <div className="right-col">
              <LazyLoadImage
                alt="Zdjęcie Airis Quartet"
                src={bioFourthImage}
                effect="opacity"
              />
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <a
            href="../files/Airis-String-Quartet-MEDIA-PACK-ENG.zip"
            className="read-more download"
            download
          >
            Download files
          </a>
        </div>
      </div>
    </StyledBio>
  );
}
