import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import bioImage from "../images/grazyna-bio.jpg";
import bioFirstImage from "../images/grazyna-bio.jpg";
import bioFirstMobile from "../images/grazyna-bio-mobile.jpg";
import bioSecondImage from "../images/grazyna-bio-2.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin: 73px 0 80px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 10%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      margin-bottom: 20px;
    }
    span {
      font-family: "Cormorant Garamond", serif;
      color: #fff;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 60px;
      z-index: 3;
    }
  }
  .mobile-image {
    display: none;
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Libre Baskerville", serif;
  }
  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 150px;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    .right-col {
      text-align: center;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 70vh;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 8rem;
    margin-top: -5rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 16px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
    }
    &.download {
      border: none;
    }
  }

  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
      &:nth-child(odd) {
        .right-col {
          width: 40%;
        }
        .left-col {
          width: 60%;
        }
      }
      &:nth-child(even) {
        .right-col {
          width: 60%;
        }
        .left-col {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        font-size: 40px;
      }
      span {
        margin-bottom: 50px;
      }
      img {
        height: 50vh;
      }
    }
    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .button-wrapper {
      padding: 3rem 0 5rem;
    }
    .single-row {
      margin-bottom: 60px;
      &:nth-child(odd) {
        flex-direction: column;
        .left-col {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }
        .right-col {
          width: 100%;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .left-col {
          width: 100%;
        }
        .right-col {
          width: 100%;
          margin-bottom: 3rem;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 160px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
        object-position: 50% 50%;
      }

      h1 {
        margin: auto 0 0 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 0 1rem;
      }
      span {
        color: #000;
        font-size: 1.5rem;
        margin: 20px 0 -130px 0;
      }
    }
    .mobile-image {
      display: block;
    }
    .desktop-image {
      display: none;
    }
  }
`;

export default function Grazyna() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledBio>
      <Helmet>
        <title>Grażyna</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/grazyna">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/grazyna-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/filmy">Filmy</NavLink>
              <NavLink to="/galeria">Zdjęcia</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Grażyna Zubik</h1>
        <span>II skrzypce</span>
        <img className="image-desktop" src={bioImage} alt="Grażyna Zubik" />
      </div>
      <div className="bio-wrapper">
        <div className="single-row">
          <div className="right-col">
            <p>
              Naukę gry na skrzypcach rozpoczęła w wieku 7 lat w{" "}
              <strong>
                Szkole Muzycznej im. Artura Malawskiego w Przemyślu
              </strong>
              , gdzie uczyła się w klasie skrzypiec Krzysztofa Nowińskiego i
              Andrzeja Gurana.  W roku 2010 ukończyła studia magisterskie na{" "}
              <strong>Akademii Muzycznej w Krakowie</strong> w klasie prof.
              Piotra Tarcholika. Jest także absolwentką{" "}
              <strong>
                Uniwersytetu Muzycznego i Sztuk Scenicznych w Wiedniu
              </strong>{" "}
              w klasie kameralnej prof. Johanessa Meissla,  
              <strong>
                Królewskiego Konserwatorium Muzycznego w Antwerpii
              </strong>{" "}
              w klasie skrzypiec prof. Tatiany Samouil  i prof. Benjamina Braude
              oraz{" "}
              <strong>
                Akademii Muzycznej im. Karola Szymanowskiego w Katowicach
              </strong>{" "}
              w klasie kameralnej prof. Piotra Szumieła. Kształciła się także
              jako contract-student w holenderskim{" "}
              <strong>ArtEZ Conservatorium</strong> w Zwolle w klasie skrzypiec
              prof. Sary Kapustin i w klasie kameralnej prof. Jeroena Reulinga
              oraz na licznych kursach mistrzowskich w kraju i z granicą.
            </p>
            <p>
              Grażyna Zubik  jest laureatką  <strong>stypendiów</strong>{" "}
              <em> Młoda Polska, Windish Kammermusik Preis </em> oraz programów
              Ministerstwa Kultury i Dziedzictwa Narodowego takich jak
              <em> Zamówienia kompozytorskie </em> oraz{" "}
              <em> Artysta-rezydent </em>. Ma w swoim dorobku także liczne
              nagrody zdobyte na ogólnopolskich i międzynarodowych
              konkursach  kameralnych, do najważniejszych należą:{" "}
              <strong>II Nagroda</strong> na II{" "}
              <em> Podkarpackim Konkursie Zespołów Kameralnych w Rzeszowie</em>
              ,  <strong>Grand prix</strong> na{" "}
              <em>
                {" "}
                International Master Competition for Musical Teachers w
                Warszawie
              </em>
              , <strong>III Nagroda</strong> na{" "}
              <em> Międzynarodowym Konkursie Muzycznym na Malcie</em>,{" "}
              <strong>
                Nagroda Specjalna za najlepsze wykonanie dzieł kompozytorów XX-
                wiecznych
              </strong>{" "}
              na{" "}
              <em>
                {" "}
                II Międzynarodowym Konkursie kwartetów Smyczkowych im. K.
                Szymanowskiego w Katowicach{" "}
              </em>
                oraz <strong>Nagroda Specjalna</strong> za{" "}
              <strong>
                najlepsze wykonanie dzieła Krzysztofa Pendereckiego
              </strong>{" "}
              na{" "}
              <em>
                {" "}
                II Międzynarodowym Konkursie Kameralnym im. L. van Beethovena w
                Lusławicach
              </em>
              .
            </p>
          </div>
          <div className="left-col">
            <figure>
              <img
                src={bioFirstMobile}
                alt="Grażyna Zubik"
                className="desktop-image"
              />
              <img
                src={bioFirstMobile}
                alt="Grażyna Zubik"
                className="mobile-image"
              />
            </figure>
          </div>
        </div>
        <div className="single-row">
          <div className="right-col">
            <LazyLoadImage
              alt="Grażyna Zubik"
              src={bioSecondImage}
              effect="opacity"
            />
          </div>
          <div className="left-col">
            <p>
              Grażyna Zubik jest bardzo aktywnym i otwartym muzykiem. Od 2010
              roku obejmuje pozycję drugiego skrzypka w{" "}
              <strong>Airis String Quartet</strong>, czemu oddaje całe swoje
              serce i zaangażowanie. Jej szerokie umiejętności i osobowość
              owocują częstymi zaproszeniami do współpracy z innymi artystami,
              zespołami czy orkiestrami takimi jak Sinfonietta Cracovia, Polish
              Art Philharmonic, CORDA Cracovia, Orkiestra Akademii
              Beethovenowskiej, NOSPR, Filharmonia Krakowska, Filharmonia
              Gorzowska. Grażyna posiada duże doświadczenie jako{" "}
              <strong>lider sekcji drugich</strong> skrzypiec w projektach bez
              dyrygenta dzięki wieloletniej współpracy z{" "}
              <strong>Przemyską Orkiestrą Kameralną</strong>, z która
              okazjonalnie koncertowała również jako solistka. Jako gościnny
              muzyk orkiestrowy w zespołach symfonicznych koncertowała pod
              batutą takich dyrygentów jak: Sir Neville Mariner, Gabriel Chmura,
              Krzysztof Penderecki, Jacek Kasprzyk, Jerzy Maksymiuk, Michał
              Klauza, Łukasz Borowicz, Marek Moś, Mark Fitz-Gerald a wynikiem
              jej działaności kameralnej i orkiestrowej były liczne nagrania CD
              i DVD oraz koncerty w Niemczech, Belgii, Holandii, Austrii,
              Czechach, Norwegii, Szwecji, Danii, Szwajcarii, Angli, Chinach,
              Kanadzie i USA.
            </p>
            <p>
              Grażyna łączy aktywną działalność koncertowa z{" "}
              <strong>pracą pedagogiczną</strong>. Od 2013 roku pracuje jako
              nauczyciel skrzypiec i zespołów kameralnych w{" "}
              <strong>Szkole Muzycznej I stopnia w Domosławicach</strong> ucząc
              dzieci od 6 do 18 roku życia i od kilku lat z pasją rozwija swoje
              umiejętności w dziedzinie nauczania metodą <strong>Suzuki</strong>
              . W pracy pedagogicznej Grażyna stawia duży nacisk na promocję
              kameralistyki na wczesnym etapie edukacji artystycznej.
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <NavLink to="/biografia" className="read-more">
            Powrót
          </NavLink>
        </div>
      </div>
    </StyledBio>
  );
}
