import React from "react";
import styled from "styled-components";
import pageLogo from "../images/airis-logo-small.png";

const StyledFooter = styled.footer`
  background: #000;
  border-top: 1px solid #2e2b26;
  width: 100%;
  padding: 40px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .social-nav {
    flex-direction: row;
    margin: 0 30px;
    li {
      margin: 0 12px;
    }
  }
  p {
    color: #fff;
    margin: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    img {
      width: 35px;
      margin: 0 10px 0 0;
    }
    a {
      color: #bf825a;
      text-decoration: none;
      margin: 0 0 0 5px;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    padding: 40px 15px 10px;
    p {
      margin-bottom: 20px;
      font-size: 0.8125rem;
      img {
        display: none;
      }
    }
    .social-nav {
      margin-bottom: 30px;
      order: -1;
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <p>
        <img src={pageLogo} alt="Logo Airis Quartet" />
        Logo designer:{" "}
        <a href="https://paulinaczajor.com" rel="noopener" target="_blank">
          {" "}
          Paulina Ewa Czajor
        </a>{" "}
      </p>
      <ul className="social-nav">
        <li className="link-facebook">
          <a
            target="_blank"
            href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
          ></a>
        </li>
        <li className="link-spotify">
          <a
            target="_blank"
            href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
          ></a>
        </li>
        <li className="link-youtube">
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
          ></a>
        </li>
        <li className="link-instagram">
          <a
            target="_blank"
            href="https://www.instagram.com/airis_quartet/"
          ></a>
        </li>
      </ul>
      <p>
        Created with ❤️ by{" "}
        <a href="https://damianjakubas.com/" rel="noopener" target="_blank">
          {" "}
          Damian Jakubas
        </a>{" "}
      </p>
    </StyledFooter>
  );
}

export default Footer;
