import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import bioImage from "../images/grazyna-bio.jpg";
import bioFirstImage from "../images/grazyna-bio.jpg";
import bioFirstMobile from "../images/grazyna-bio-mobile.jpg";
import bioSecondImage from "../images/grazyna-bio-2.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin: 73px 0 80px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 10%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
      margin-bottom: 20px;
    }
    span {
      font-family: "Cormorant Garamond", serif;
      color: #fff;
      font-weight: 600;
      font-size: 1.75rem;
      margin-bottom: 60px;
      z-index: 3;
    }
  }
  .mobile-image {
    display: none;
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Libre Baskerville", serif;
  }
  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 150px;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    .right-col {
      text-align: center;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
      max-height: 70vh;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 8rem;
    margin-top: -5rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 16px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
    }
    &.download {
      border: none;
    }
  }

  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
      &:nth-child(odd) {
        .right-col {
          width: 40%;
        }
        .left-col {
          width: 60%;
        }
      }
      &:nth-child(even) {
        .right-col {
          width: 60%;
        }
        .left-col {
          width: 40%;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        font-size: 40px;
      }
      span {
        margin-bottom: 50px;
      }
      img {
        height: 50vh;
      }
    }
    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .button-wrapper {
      padding: 3rem 0 5rem;
    }
    .single-row {
      margin-bottom: 60px;
      &:nth-child(odd) {
        flex-direction: column;
        .left-col {
          width: 100%;
          margin-bottom: 0;
          padding: 0;
        }
        .right-col {
          width: 100%;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .left-col {
          width: 100%;
        }
        .right-col {
          width: 100%;
          margin-bottom: 3rem;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 160px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
        object-position: 50% 50%;
      }

      h1 {
        margin: auto 0 0 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
        padding: 0 1rem;
      }
      span {
        color: #000;
        font-size: 1.5rem;
        margin: 20px 0 -130px 0;
      }
    }
    .mobile-image {
      display: block;
    }
    .desktop-image {
      display: none;
    }
  }
`;

export default function GrazynaEN() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledBio>
      <Helmet>
        <title>Grażyna</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/grazyna">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/grazyna-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Grażyna Zubik</h1>
        <span>2nd violin </span>
        <img className="image-desktop" src={bioImage} alt="Grażyna Zubik" />
      </div>
      <div className="bio-wrapper">
        <div className="single-row">
          <div className="right-col">
            <p>
              Grażyna started her violin education at the age of 7 at the{" "}
              <strong> Artur Malawski Music School in Przemyśl </strong> where
              she studied 12 years in a violin class of the prof. Krzysztof
              Nowiński and prof. Andrzej Guran. In 2005 she got accepted for the
              bachelor’s and master’s studies at the{" "}
              <strong> Academy of Music in Cracow </strong>
              for a violin class of the prof. Piotr Tarcholik and graduated in
              2010. She continued her education at the{" "}
              <strong> ArtEZ Conservatorium Zwolle </strong> in class of prof.
              Jeroen Reuling and prof. Sarah Kapustin,{" "}
              <strong>
                {" "}
                Universität für Musik und darstellende Kunst in Vienna{" "}
              </strong>{" "}
              in class of prof. Johannes Meissl,{" "}
              <strong> Royal Conservatory Antwerp </strong> in class of prof.
              Tatiana Samouil and prof. Benjamin Braude and at the{" "}
              <strong> Karol Szymanowski Academy of Music in Katowice </strong>{" "}
              in class of prof. Piotr Szumieł. She attended a number of chamber
              music, solo and orchestra masterclasses in Poland and abroad.
            </p>
            <p>
              Grażyna Zubik is a <strong> laureate </strong> of many artistic
              <strong> scholarships </strong> such as Young Poland scholarship
              of the Ministry of Culture and National Heritage,{" "}
              <em> Windish Kammermusik Preis </em> and national artistic grants
              like <em> Artist in residence </em> or{" "}
              <em> Composition orders </em>, to name a few. She is also a
              laureate of many competition prizes. The most important are:{" "}
              <strong> 2nd prize </strong> at the{" "}
              <em>
                {" "}
                II Subcarpathian Chamber Ensembles Competition in Rzeszów
              </em>
              , <strong> 3rd prize </strong> at the{" "}
              <em> International Music Competition in Malta</em>,{" "}
              <strong>
                {" "}
                Special prize for the best performance of the XXth century
                repertoire{" "}
              </strong>{" "}
              at the{" "}
              <em>
                {" "}
                Karol Szymanowski International String Quartet Competition{" "}
              </em>
              and{" "}
              <strong>
                {" "}
                Special Prize for the best performance of the piece composed by
                Krzysztof Penderecki{" "}
              </strong>{" "}
              at the{" "}
              <em>
                {" "}
                II Ludvig van Beethoven International Chamber Music Competition
                in Lusławice
              </em>
              .
            </p>
          </div>
          <div className="left-col">
            <figure>
              <img
                src={bioFirstMobile}
                alt="Grażyna Zubik"
                className="desktop-image"
              />
              <img
                src={bioFirstMobile}
                alt="Grażyna Zubik"
                className="mobile-image"
              />
            </figure>
          </div>
        </div>
        <div className="single-row">
          <div className="right-col">
            <LazyLoadImage
              alt="Grażyna Zubik"
              src={bioSecondImage}
              effect="opacity"
            />
          </div>
          <div className="left-col">
            <p>
              Grażyna Zubik is a very active musician. She holds a position of a
              second violinist in <strong> Airis String Quartet </strong> since
              2010 which she devotes her heart and engagement. As an openminded
              and passionate musician Grażyna often invited to collaborate with
              different chamber music ensembles and string and symphonic
              orchestras e.g. Sinfonietta Cracovia, Polish Art Philharmonic,
              CORDA Cracovia, Beethoven Academy Orchestra. She has a great
              experience as a <strong> second violin leader </strong> in string
              orchestra <strong> without conductor </strong> due to a long term
              valuable collaboration with{" "}
              <strong> Przemyśl Chamber Orchestra </strong> where she
              occasionally performed as a <strong> soloist </strong> too. As a
              <strong> guest player </strong> in symphony orchestras she
              performed under renowned conductors like Sir Neville Mariner,
              Gabriel Chmura, Jacek Kasprzyk, Jerzy Maksymiuk, Michał Klauza,
              Łukasz Borowicz, Marek Moś, Mark Fitz-Gerald in Germany, Belgium,
              Holland, Austra, Norway, Sweden, Denmark, Switzerland, England,
              China, USA and Canada.
            </p>
            <p>
              Grażyna Zubik shares her time between active performing and
              <strong> teaching </strong>. Since 2013 she works as a violin and
              chamber music teacher in{" "}
              <strong> Music School in Domosławice </strong> teaching children
              from the age of 6 to 18 and for past few years she is developing
              her skills in <strong> Suzuki Method </strong> what gives her
              pleasure and satisfaction. Grażyna puts a big effort into{" "}
              <strong> chamber music promotion </strong> from the early stage of
              children’s artistic education.
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <NavLink to="/biography" className="read-more">
            Back
          </NavLink>
        </div>
      </div>
    </StyledBio>
  );
}
