import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import arrowRight from "../images/arrow-right-small.svg";
import arrowBottom from "../images/../images/arrow-bottom.svg";
import bioImage from "../images/bio-image.jpg";
import bioFirstImage from "../images/bio-first-image.jpg";
import bioSecondImage from "../images/bio-second-image.jpg";
import bioThirdImage from "../images/bio-third-image.jpg";
import bioFourthImage from "../images/bio-fourth-image.jpg";
import pageLogo from "../images/airis-logo-small.png";
import homeImage from "../images/home-image.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const StyledBio = styled.section`
  .posts-image-wrapper {
    width: 100vw;
    height: calc(100vh - 73px);
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 73px 0 70px;
    &:before {
      content: "";
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.07) 30%
      );
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: calc(100vh - 73px);
      object-fit: cover;
      object-position: 50% 30%;
    }

    h1 {
      font-family: "Cormorant Garamond", serif;
      position: relative;
      z-index: 3;
      margin-bottom: 160px;
      font-size: 50px;
      color: #fff;
      line-height: 1em;
    }
  }
  .bio-wrapper {
    max-width: 1238px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
    font-family: "Libre Baskerville", serif;
  }

  .single-row {
    display: flex;
    position: relative;
    align-items: center;
    .left-col,
    .right-col {
      padding: 0 32px;
      width: 50%;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-align: justify;
      margin-bottom: 2rem;
      strong {
        font-weight: 700;
      }
      em {
        font-style: italic;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.2em;
    text-align: center;
    margin: 0 15px 80px 15px;
    font-weight: 500;
    font-family: "Cormorant Garamond", serif;
  }
  .team-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0 15px 80px 15px;
    li {
      margin-bottom: 10px;
      a {
        text-decoration: none;
        font-size: 1rem;
        line-height: 1.3em;
        color: #000;
        transition: 0.25s ease-out;
        font-weight: 500;
        position: relative;
        padding-left: 1.5rem;
        &:before {
          content: "";
          position: absolute;
          width: 0.875rem;
          height: 0.875rem;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          left: 0;
          background: url(${arrowBottom}) no-repeat center center;
          background-size: contain;
          transition: 0.25s ease-out;
          z-index: 2;
        }
        &:hover {
          color: #bf825a;
        }
        span {
          font-weight: 700;
        }
      }
    }
  }
  .button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem 0 3rem;
  }
  .read-more {
    font-family: "Montserrat", sans-serif;
    background: transparent;
    outline: none;
    border: none;
    padding: 8px 36px 8px 8px;
    position: relative;
    font-size: 0.875rem;
    border: 1px solid #2e2b26;
    color: #2e2b26;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    transition: 0.25s ease-out;
    text-decoration: none;
    cursor: pointer;
    &.active {
      display: none;
    }
    &:after {
      content: "";
      position: absolute;
      width: 0.875rem;
      height: 0.875rem;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      right: 0.75rem;
      background: url(${arrowRight}) no-repeat center center;
      background-size: contain;
      transition: 0.25s ease-out;
    }
    &:hover {
      background: #bf825a;
      border: 1px solid #bf825a;
      color: #fff;
      &:after {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(34deg)
          brightness(103%) contrast(101%);
      }
    }
    &.download {
      border: none;
      padding: 8px;
      &:after {
        display: none;
      }
    }
  }
  .expanded-wrapper {
    display: none;
    position: relative;
    &.active {
      display: block;
    }
    .scroll-target {
      position: absolute;
      left: 0;
      top: -150px;
    }
    .single-row {
      margin-bottom: 150px;
    }
  }
  @media (max-width: 1200px) {
    .single-row {
      .left-col,
      .right-col {
        padding: 0 20px;
      }
    }

    .expanded-wrapper {
      .single-row {
        &:nth-child(odd) {
          .right-col {
            width: 40%;
          }
          .left-col {
            width: 60%;
          }
        }
        &:nth-child(even) {
          .right-col {
            width: 60%;
          }
          .left-col {
            width: 40%;
          }
        }
      }
    }
  }
  @media (max-width: 991px) {
    .posts-image-wrapper {
      margin-bottom: 60px;
      height: 50vh;
      &:before {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.052240964745273066) 50%
        );
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
      }
      img {
        height: 50vh;
      }
    }
    .button-wrapper {
      padding: 1rem 0 2rem;
    }

    h2 {
      font-size: 2.25rem;
      margin-bottom: 50px;
    }
    .team-links {
      li {
        text-align: center;
        a {
          text-align: center;
          font-size: 0.9375rem;
        }
      }
      + .single-row {
        flex-direction: column;
        .left-col,
        .right-col {
          width: 100%;
          padding: 0 20px;
        }
      }
    }
    .expanded-wrapper {
      .single-row {
        margin-bottom: 60px;
        &:nth-child(odd) {
          flex-direction: column-reverse;
          .right-col {
            width: 100%;
            margin-bottom: 3rem;
            padding: 0;
          }
          .left-col {
            width: 100%;
          }
        }
        &:nth-child(even) {
          flex-direction: column;
          .right-col {
            width: 100%;
          }
          .left-col {
            width: 100%;
            margin-bottom: 3rem;
            padding: 0;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .posts-image-wrapper {
      height: 40vh;
      h1 {
        font-size: 30px;
      }
      img {
        height: 40vh;
      }
    }
  }
  @media (max-width: 575px) {
    .posts-image-wrapper {
      margin-top: 68px;
      margin-bottom: 120px;
      height: calc(50vh - 76px);
      img {
        height: 100%;
      }

      h1 {
        margin: auto 0 -80px 0;
        color: #000;
        z-index: 3;
        font-size: 2rem;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 350px) {
    .team-links {
      li {
        a {
          font-size: 0.875rem;
        }
      }
    }
  }
`;

export default function Bio() {
  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  const [isExpanded, setExpanded] = useState("false");
  const ToggleExpanded = () => {
    setExpanded(!isExpanded);
    setTimeout(() => {
      document.querySelector(".scroll-target").scrollIntoView();
    }, 150);
  };

  return (
    <StyledBio>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Biografia</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/" className="brand-logo-small">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/biografia">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/biography">EN</NavLink>
          </li>
        </ul>

        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biografia">Biografia</NavLink>
            </li>
            <li>
              <NavLink to="/aktualnosci">Aktualności</NavLink>
            </li>
            <li>
              <NavLink to="/koncerty">Koncerty</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/filmy">Filmy</NavLink>
              <NavLink to="/galeria">Zdjęcia</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertuar</NavLink>
            </li>
            <li>
              <NavLink to="/wspolpraca">Projekty i Współpraca</NavLink>
            </li>
            <li>
              <NavLink to="/dyskografia">Dyskografia</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Kontakt</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="posts-image-wrapper">
        <h1>Biografia</h1>
        <img
          className="image-desktop"
          src={bioImage}
          alt="Zdjęcie Airis Quartet"
        />
      </div>
      <div className="bio-wrapper">
        <h2>Airis String Quartet</h2>
        <ul className="team-links">
          <li>
            <NavLink to="/aleksandra">
              Aleksandra Czajor-Kowalska <span> I skrzypce</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/grazyna">
              Grażyna Zubik<span> II skrzypce</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/malwina">
              Malwina Tkaczyk<span> altówka</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/mateusz">
              Mateusz Mańka<span> wiolonczela</span>
            </NavLink>
          </li>
        </ul>
        <div className="single-row">
          <div className="left-col">
            <p>
              <strong>
                <em>Airis String Quartet </em>
              </strong>{" "}
              powstał w 2008 roku w Krakowie z nieokiełznanej studenckiej pasji
              do muzyki współczesnej. Kompozycje współczesnych polskich
              kompozytorów na czele z kwartetami Krzysztofa Pendereckiego, a
              następnie światowa literatura XX i XXI wieku od początku stanowiły
              fascynujące pole zespołowej eksploracji, choć zupełnie
              niestandardowe jak na repertuar dedykowany młodemu kwartetowi
              smyczkowemu. Odważny skok na głęboką wodę bardzo szybko przyniósł
              zespołowi pierwsze sukcesy konkursowe i uznanie publiczności, a
              studenckie marzenie o profesjonalnej karierze w kwartecie
              smyczkowym przerodziło się w świadomą decyzję o wyborze
              artystycznej drogi.
            </p>
          </div>
          <div className="right-col">
            <p>
              Zespół działa nieprzerwanie już <strong>ponad 14 lat</strong>.
              Uznawany obecnie za jeden z najciekawszych polskich kwartetów
              smyczkowych młodego pokolenia jest zdobywcą wielu nagród na
              miedzynarodowych konkursach, laureatem stypendiów i programów
              dotacyjnych. Ma na swoim koncie liczne nagrania płytowe,
              prawykonania, współpracę z wybitnymi artystami i kompozytorami,
              edukację w prominentnych ośrodkach muzycznych w Europie.{" "}
              <em> Airis String Quartet</em> wyróżnia niezwykła ekspresja
              sceniczna, szczerość emocji i szczególny zmysł w wykonawstwie
              muzyki XX i XXI wieku.
            </p>
          </div>
        </div>
        <div className="button-wrapper">
          <button
            type="button"
            onClick={ToggleExpanded}
            className={isExpanded ? "read-more" : "read-more active"}
          >
            Czytaj dalej
          </button>
        </div>

        <div
          className={
            isExpanded ? "expanded-wrapper" : "expanded-wrapper active"
          }
        >
          <div className="scroll-target"></div>
          <div className="single-row">
            <div className="left-col">
              <figure>
                <img src={bioFirstImage} alt="Zdjęcie Airis Quartet" />
              </figure>
            </div>
            <div className="right-col">
              <p>
                <strong>
                  <em>Airis String Quartet </em>
                </strong>{" "}
                powstał w <strong>2008</strong> roku w Akademii Muzycznej im. K.
                Pendereckiego w Krakowie. Pierwsze kwartetowe kroki stawiał pod
                kierunkiem muzyków z <em>Kwartetu Dafô</em>, początkowo przy
                katedrze muzyki współczesnej a następnie również w katedrze
                kameralistyki. W niespełna pół roku od swojego powstania zespół
                osiągnął pierwszy znaczący sukces – zdobył{" "}
                <strong>II nagrodę</strong> na{" "}
                <em>
                  Międzynarodowym Konkursie Współczesnej Muzyki Kameralnej
                </em>{" "}
                w Krakowie. Wydarzenie to w znaczącym stopniu ukierunkowało
                kwartet w poszukiwaniach{" "}
                <strong>własnej artystycznej niszy</strong>.
              </p>
              <p>
                Kolejne lata przyniosły wiele innych{" "}
                <strong>nagród i wyróżnień</strong>, m.in. na takich konkursach
                jak: <em>Charles Hennen Competition</em> w Holandii (
                <strong>EMCY Prize</strong> for Excellent Performance, 2012),
                <em> Malta International Music Competition</em> (
                <strong>III nagroda</strong>, 2015),{" "}
                <em>Międzynarodowy Konkurs Kwartetów Smyczkowych</em> im. Karola
                Szymanowskiego w Katowicach (
                <strong>
                  Nagroda Specjalna za najlepsze wykonanie utworu kompozytora XX
                  wieku
                </strong>
                , 2017) oraz{" "}
                <em>
                  Międzynarodowy Konkurs Muzyki Kameralnej im. Ludwiga van
                  Beethovena
                </em>{" "}
                w Lusławicach (
                <strong>
                  Nagroda Specjalna za najlepsze wykonanie utworu Krzysztofa
                  Pendereckiego
                </strong>
                , 2017). Zespół był także <strong>laureatem</strong> dwóch
                programów stypendialnych: Ministra Kultury i Dziedzictwa
                Narodowego <em>Młoda Polska</em> (2016) i Instytutu Muzyki i
                Tańca <em>Artysta-rezydent </em>(2015).
              </p>
            </div>
          </div>
          <div className="single-row">
            <div className="left-col">
              <p>
                Poza Akademią Muzyczną im. Krzysztofa Pendereckiego w Krakowie
                zespół kształcił się także w{" "}
                <strong>ArtEZ Conservatorium</strong> w Zwolle w Holandii w
                ramach studiów w klasie Jeroena Reulinga i Sary Kapustin (
                <em>Rubens Quartet</em>) , na{" "}
                <strong>
                  Universität für Musik und darstellende Kunst w Wiedniu
                </strong>{" "}
                w ramach studiów podyplomowych w klasie Johannesa Meissla (
                <em>Artis Quartet</em>) oraz w{" "}
                <strong>
                  Akademii Muzycznej im. Karola Szymanowskiego w Katowicach
                </strong>{" "}
                w ramach studiów podyplomowych w klasie prof. Piotra Szumieła (
                <em>Apollon Musagète Quartett</em>). Zespół był również
                członkiem prestiżowej{" "}
                <strong>European Chamber Music Academy (ECMA)</strong>, a
                podczas licznych kursów mistrzowskich miał okazję pracować z
                wybitnymi artystami z takich zespołów, jak{" "}
                <em>
                  Ysaÿe Quartet, Quatuor Diotima, Alban Berg Quartet, Melos
                  Quartet, Quatuor Danel, Szymanowski Quartet, Silesian String
                  Quartet, Royal String Quartet
                </em>{" "}
                czy <em>Apollon Musagète Quartett</em>. 
              </p>
              <p>
                Zespół miał także szansę uczestniczyć w niezwykłych kursach
                muzyki współczesnej <strong>Diotima Academy</strong> we Francji
                dla kwartetów smyczkowych i młodych kompozytorów, pod kierunkiem
                francuskiego <em>Diotima Quatour</em>. W kameralnym gronie (trzy
                kwartety i trzech kompozytorów z różnych krajów Europy)
                uczestnicy poznawali wzajemnie{" "}
                <strong>
                  {" "}
                  perspektywę twórcy i wykonawcy w procesie tworzenia muzyki
                  nowej
                </strong>
                , tajniki technik wykonawczych i notacyjnych oraz eksplorowali
                skrajne możliwości brzmieniowe instrumentu. Kursy Diotima
                Academy były bardzo ważnym punktem w rozwoju zespołu.
              </p>
              <p>
                Obecnie sami członkowie kwartetu realizują się również jako{" "}
                <strong>pedagodzy</strong> dzieląc zdobytym doświadczeniem i
                swoją pasją z młodszym pokoleniem- pracują jako wykładowcy na
                <strong>
                  {" "}
                  Akademii Muzycznej im. Karola Szymanowskiego w Katowicach i
                  Akademii Muzycznej im. Krzysztofa Pendereckiego w Krakowie
                </strong>{" "}
                oraz uczą w <strong>szkołach muzycznych</strong> w Opolu,
                Bytomiu, Dąbrowie Górniczej i Domosławicach. Aktywnie działają
                na rzecz popularyzacji kameralistyki od najwcześniejszych etapów
                edukacji artystycznej.
              </p>
            </div>
            <div className="right-col">
              <LazyLoadImage
                alt="Zdjęcie Airis Quartet"
                src={bioSecondImage}
                effect="opacity"
              />
            </div>
          </div>
          <div className="single-row">
            <div className="left-col">
              <LazyLoadImage
                alt="Zdjęcie Airis Quartet"
                src={bioThirdImage}
                effect="opacity"
              />
            </div>
            <div className="right-col">
              <p>
                Pierwsze lata działalności i rozwoju zespołu oddane były bez
                reszty <strong>muzyce XX i XXI wieku</strong>, od Szostakowicza,
                Bartóka, przez atonalność, sonoryzm, aleatoryzm aż do dzieł
                najnowszych. Kwartet eksplorował też intensywnie twórczość{" "}
                <strong>współczesnych kompozytorów Krakowskich</strong> (czego
                owocem było np. nagranie pierwszej płyty dla Akademii Muzycznej
                w Krakowie z dziełami kameralnymi Zbigniewa Bujarskiego i Marka
                Stachowskiego). Chęć dalszego rozwoju jako profesjonalny kwartet
                smyczkowy naturalnie popchnęła zespół do podjęcia repertuaru{" "}
                <strong>wcześniejszych epok</strong>, poszukiwania artystów i
                pedagogów specjalizujących się w konkretnych okresach i nurtach
                muzycznych. Były to ekscytujące{" "}
                <strong>lata intensywnej edukacji</strong> na licznych
                miedzynarodowych kursach muzycznych, studiach w Holandii,
                Wiedniu, Belgii, lekcjach prywatnych z artystami uznanymi na
                świecie, podczas których kwartet poznawał tajniki bogactwa figur
                retorycznych w języku muzycznym klasyków Wiedeńskich, estetyki
                wykonawczej muzyki francuskiej, interpretacji i kontekstów dzieł
                romantycznych i późno romantycznych, technik notacyjnych i
                wykonawczych muzyki współczesnej.
              </p>
              <p>
                Obecnie <strong>repertuar</strong> kwartetu jest bardzo{" "}
                <strong>szeroki i różnorodny</strong>. Zespół chętnie zestawia
                ze sobą dzieła różnych epok, dobierając je na zasadzie klucza
                <strong> dialogu, kontekstów, połączeń</strong>. W{" "}
                <strong> muzyce współczesnej </strong> kwartet odnajduje{" "}
                wolność, wyzwanie, dzikość, czyste karty do zapisania i pole dla
                kreatywnej interpretacji wymykające się kanonom tradycji
                wykonawczej. W <strong>klasyce</strong> odnajduje wytchnienie,
                porządek, genialny balans, przestrzeń do doskonalenia kunsztu
                instrumentalnego oraz zakorzenienie.
              </p>
            </div>
          </div>
          <div className="single-row">
            <div className="left-col">
              <p>
                Zespół stale poszukuje <strong>nowych inspiracji</strong>,
                przestrzeni dla kreatywności i wolności twórczej. Chętnie sięga
                po repertuar <strong>nieoczywisty i nieznany</strong> (np.
                debiutancka płyta kwartetu <em>Hartmann</em> w całości
                poświęcona fascynującej twórczości zapomnianego
                antynazistowskiego niemieckiego kompozytora okresu II wojny
                światowej) i dokonuje licznych <strong>prawykonań</strong> (np.
                z jego inicjatywy powstało wiele nowych dzieł w ramach programu
                dotacyjnego IMIT <em>Zamówienia kompozytorskie</em>) -
                współpracował z kompozytorami takimi jak Miłosz Bembinow, Rafał
                Augustyn, Ib Nørholm, Paweł Łukaszewski, Michał Dobrzyński,
                Łukasz Pieprzyk, Anders Koppel, Clarck Nichols, Christian Mason.{" "}
                <em>Airis String Quartet</em> miał także ogromny zaszczyt
                dwukrotnie pracować z{" "}
                <strong>Maestro Krzysztofem Pendereckim</strong> nad jego II i
                III Kwartetem smyczkowym, które zajmują stałe miejsce w
                repertuarze zespołu i przyniosły artystom liczne nagrody na
                konkursach.
              </p>
              <p>
                Zespół czerpie ogromną inspirację ze <strong>współpracy</strong>{" "}
                z innymi muzykami i zespołami. Wśród projektów kwartetu znajdują
                się kolaboracje z duetem akordeonowym{" "}
                <strong>Accosphere Duo</strong>, z triem perkusyjnym{" "}
                <strong>Amadrums</strong>, z{" "}
                <strong>zespołami wokalnymi</strong> (w wyniku tych kolaboracji
                powstały m.in. nagrania płytowe{" "}
                <em>
                  Accordion Anatomy, Airis String Quartet/Amadrums Trio/Łukasz
                  Pieprzyk
                </em>{" "}
                na septet instrumentalny i elektronikę, <em>Ave Maria </em> na
                kwartet i chór dziewczęcy <em>Puellae Orantes</em>). Zespół
                koncertuje także w <strong>kwintetach</strong> z klarnetem,
                saksofonem, fortepianem czy głosem. W 2021 roku{" "}
                <em>Airis String Quartet</em> dokonał także nagrań kompozycji
                Clarcka Nicholsa i Andersa Koppela na kwartet smyczkowy i
                mandolinę z wybitnym wirtuozem tego instrumentu Alonem Sarielem
                (płyta ujrzy światło dzienne w 2022 roku).
              </p>
              <p>
                <em> Airis String Quartet</em> eksploruje także{" "}
                <strong>inne gatunki muzyczne</strong> - dokonał nagrań{" "}
                <strong>muzyki do gier komputerowych</strong> (
                <em>Kholat, Father and son</em>),{" "}
                <strong>ścieżki dźwiękowej</strong> Krzysztofa A. Janczaka do
                polsko-francuskiego <strong>filmu dokumentalnego</strong>{" "}
                Krzysztofa Rogulskiego <em>Dans les pas de Marie Curie</em>. W
                2015 roku w studiu nagraniowym w Miami na Florydzie dokonał
                nagrań płyty <em>Hello</em> wraz z zespołem pop/RnB{" "}
                <em>Daddy’s Cash Band</em> a od 2018 roku współpracuje z
                zespołem Nocą Umówieni z którym nagrał dwie płyty{" "}
                <em>Nocą umówieni</em>(2018) i <em>Ginczanka</em> (2021)
                utrzymane w stylu muzycznym zwanym <em>lyric fusion</em>{" "}
                czerpiącym z poezji śpiewanej, jazzu, muzyki klasycznej i
                piosenki aktorskiej.
              </p>
            </div>
            <div className="right-col">
              <LazyLoadImage
                alt="Zdjęcie Airis Quartet"
                src={bioFourthImage}
                effect="opacity"
              />
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <a
            href="../files/Airis-String-Quartet-MEDIA-PACK-PL.zip"
            className="read-more download"
            download
          >
            Pobierz pliki
          </a>
        </div>
      </div>
    </StyledBio>
  );
}
