import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import homeImage from "../images/home-image.jpg";
import homeImageMobile from "../images/home-image-mobile.jpg";
import arrowBottom from "../images/arrow-bottom.svg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import pageLogo from "../images/airis-logo.png";
import secondViewBg from "../images/second-view-bg.jpg";
import secondViewMobile from "../images/second-view-bg-mobile.jpg";

const StyledHome = styled.section`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  .first-view,
  .second-view {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #000;
  }
  .arrow-bottom {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    animation: moveBottom 2s ease-in-out infinite;
    &.active {
      opacity: 1;
    }
  }
  .hero-image,
  .second-view-bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: 50% 50%;
  }
  .second-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 120px 0 150px;
    height: auto;
    .quote {
      display: flex;
      flex-wrap: wrap;
      max-width: 700px;
      margin: 0 auto 120px;
      padding: 0 20px;
      p {
        font-size: 1.25rem;
        color: #bf825a;
        font-weight: 400;
        display: block;
        width: 100%;
        margin-bottom: 2.25rem;
        line-height: 1.4em;
        text-align: center;
        em {
          font-style: italic;
        }
      }
      span {
        font-size: 1rem;
        color: #bf825a;
        display: block;
        width: 100%;
        text-align: right;
        line-height: 1.4em;
      }
    }
    iframe {
      position: relative;
      z-index: 3;
      margin: 0 20px;
    }
  }

  .second-view-bg-mobile,
  .second-view-bg {
    display: none;
  }

  .social-nav {
    position: absolute;
    left: 30px;
    bottom: 8px;
  }

  @keyframes moveBottom {
    0% {
      transform: translateX(-50%) translateY(0);
    }
    50% {
      transform: translateX(-50%) translateY(15px);
    }
    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  @media (max-height: 750px) {
    iframe {
      height: 300px;
      width: 550px;
    }
  }

  @media (max-width: 768px) {
    iframe {
      height: 300px;
      width: 550px;
    }
    .social-nav {
      display: none;
    }
  }
  @media (max-width: 575px) {
    .first-view {
      height: calc(100vh - 80px);
    }
    .hero-image {
      object-position: 43% 50%;
    }
    .arrow-bottom {
      bottom: 80px;
    }
    iframe {
      height: 250px;
      width: 460px;
    }
    .second-view {
      padding: 100px 0 80px;
      overflow: hidden;
      .quote {
        margin-bottom: 100px;
        p {
          font-size: 1rem;
        }
        span {
          font-size: 0.875rem;
        }
      }
    }
  }
  @media (max-width: 500px) {
    iframe {
      height: 190px;
      width: 410px;
    }
  }
  @media (max-width: 450px) {
    iframe {
      width: 360px;
    }
  }
  @media (max-width: 400px) {
    iframe {
      width: 310px;
      height: 180px;
    }
  }
  @media (max-width: 350px) {
    iframe {
      width: 260px;
      height: 160px;
    }
  }
`;

export default function HomeEn() {
  useEffect(() => {
    const toggleArrow = () => {
      document.querySelector(".arrow-bottom").classList.add("active");
    };
    setTimeout(toggleArrow, 3000);
  }, []);

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  const [isVisible, setVisible] = useState("false");
  const ToggleVisible = () => {
    setVisible(!isVisible);
  };

  return (
    <StyledHome>
      <Helmet>
        <title>Airis String Quartet</title>
      </Helmet>
      <nav className={isActive ? "main-navbar" : "main-navbar active"}>
        <NavLink to="/home-en" className="brand-logo">
          <img src={pageLogo} alt="Logo Airis Quartet" />
        </NavLink>
        <ul className="lang-switcher">
          <li className="lang-pl">
            <NavLink to="/">PL</NavLink>
          </li>
          <li className="lang-en">
            <NavLink to="/home-en">EN</NavLink>
          </li>
        </ul>
        <div
          className={isActive ? "burger" : "burger active"}
          onClick={ToggleClass}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="nav-links-wrapper">
          <img src={homeImage} alt="Logo Airis Quartet" />
          <ul className="nav-links">
            <li>
              <NavLink to="/home-en">Home</NavLink>
            </li>
            <li>
              <NavLink to="/biography">Biography</NavLink>
            </li>
            <li>
              <NavLink to="/posts">News</NavLink>
            </li>
            <li>
              <NavLink to="/concerts">Concerts</NavLink>
            </li>
            <li
              className={isVisible ? "btn-media" : "btn-media active"}
              onClick={ToggleVisible}
            >
              Media
            </li>
            <li
              className={isVisible ? "media-wrapper" : "media-wrapper active"}
            >
              <NavLink to="/movies">Movies</NavLink>
              <NavLink to="/galeria">Photos</NavLink>
            </li>
            <li>
              <NavLink to="/repertuar">Repertoire</NavLink>
            </li>
            <li>
              <NavLink to="/partnership">Projects and Collaboration</NavLink>
            </li>
            <li>
              <NavLink to="/discography">Discography</NavLink>
            </li>
            <li>
              <NavLink to="/kontakt">Contact</NavLink>
            </li>
          </ul>
          <ul className="social-nav">
            <li className="link-facebook">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
              ></a>
            </li>
            <li className="link-spotify">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
              ></a>
            </li>
            <li className="link-youtube">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
              ></a>
            </li>
            <li className="link-instagram">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/airis_quartet/"
              ></a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="first-view">
        <img
          src={homeImage}
          alt="Airis Quartet"
          className="hero-image image-desktop"
        />
        <ul className="social-nav">
          <li className="link-facebook">
            <a
              target="_blank"
              href="https://www.facebook.com/Airis-String-Quartet-164319146944070/"
            ></a>
          </li>
          <li className="link-spotify">
            <a
              target="_blank"
              href="https://open.spotify.com/artist/7JAvj4oRl8SjheV4aEUErK"
            ></a>
          </li>
          <li className="link-youtube">
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCITa7fR55f4lMQTbbQVBEkw"
            ></a>
          </li>
          <li className="link-instagram">
            <a
              target="_blank"
              href="https://www.instagram.com/airis_quartet/"
            ></a>
          </li>
        </ul>
        <img src={arrowBottom} alt="strzałka w dół" className="arrow-bottom" />
      </div>
      <div className="second-view">
        <div className="quote">
          <p>
            <em>
              "Airis String Quartet is a phenomenon - <br /> four musicians with
              one soul"
            </em>
          </p>
          <span>Yaakov Lemmer, Jewish Festival Cracow (2022)</span>
        </div>
        <iframe
          src="https://www.youtube.com/embed/jOd6wH3LjQg?start=119"
          width="700"
          height="388"
          frameBorder="0"
        ></iframe>
      </div>
    </StyledHome>
  );
}
